import { useState } from "react";
import Popboxs from "./Popboxs";
import Popboxs2 from "./Popboxs2";
import Sort from "../../../components/List/Sort";
import Pagination from "../../../components/List/Pagination";
import Table from "../../../components/List/Table";
import { useGetUserlistQuery } from "../../../store/api/userapi";
import Convertdate from "../../../components/List/Convertdate";
import Loadercomp from "../../../components/Loadercomp";
import Filter from "../../../components/List/Filter";

const Userlist = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [filterValues, setFilterValues] = useState({});
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };
  const [sidebarVisibletwo, setSidebarVisibletwo] = useState(false);
  const toggleSidebartwo = () => {
    setSidebarVisibletwo(!sidebarVisibletwo);
  };

  const action = [{ icon: "view", url: "/view-user" }];
  const headfields = {
    0: { label: "S.NO", key: "serial_no", increameant: true },
    1: { label: "Nickname", key: "nick_name" },
    3: { label: "Gender", key: "gender" },
    4: { label: "Designation", key: "account.designation" },
    5: { label: "Email ID", key: "account.email" },
    7: { label: "Mobile No.", key: "account.phone_number" },
    8: { label: "Created Date & Time",
      key: "account.date_joined",
      formatter: Convertdate,
    },
  };
  const filters = {
    0: { key: "nickname", type: "text", placeholder: "Nickname" },
    1: { key: "desigination", type: "text", placeholder: "Desigination" },
    2: { key: "email", type: "text", placeholder: "Email" },
    3: { key: "mobile", type: "number", placeholder: "Mobile number" },
    4: { key: "created_date", type: "datetime", placeholder: "Created At" },
  };
  const filterComponents = Object.values(filters).map((filter) => ({
    ...filter,
    value: filterValues[filter.key] || "",
  }));
  const handleFilterChange = (key, value) => {
    setFilterValues((prev) => ({ ...prev, [key]: value }));
  };
  const queryParameters = Object.values(filters).reduce((acc, filter) => {
    acc[filter.key] = filterValues[filter.key] || "";
    return acc;
  }, {});
  const { data: tabledata, isLoading } = useGetUserlistQuery({
    page: currentPage,
    pageSize,
    ...queryParameters,
  });

  return (
    <div className="minheight" style={{ width: "100%", minHeight: "100vh" }}>
      <div className="dashboardcontent px-2">
        <div className="container-fluid px-2">
          <div className="row bg-white py-3 rounded-top">
            <div className="col-lg-3 d-flex justify-content-between align-items-center">
              <p className="m-0 customfont teenheadertitle">User List</p>
            </div>
            <div
              className="col-lg-9 d-flex justify-content-end align-items-center"
              style={{ gap: "7px" }}
            >
              <div className="addnew d-lg-flex d-none" style={{ gap: "4px" }}>
                <button
                  className="btn text-white customcolor2"
                  style={{ fontSize: "13px" }}
                  onClick={toggleSidebartwo}
                >
                  + Add Avatars
                </button>
                <button
                  className="btn text-white customcolor2"
                  style={{ fontSize: "13px" }}
                  onClick={toggleSidebar}
                >
                  + Add Concentrate
                </button>
              </div>
            </div>
          </div>
          {isLoading === true ? (
            <>
              <div className="d-flex justify-content-center mt-4">
                <Loadercomp />{" "}
              </div>
            </>
          ) : (
            <div
              className="row bg-white pb-4 rounded-bottom table-responsive"
              style={{ paddingBottom: "7rem" }}
            >
              <Filter
                filters={filterComponents}
                onFilterChange={handleFilterChange}
              />
              <Table
                headfields={headfields}
                action={action}
                tabledata={tabledata.results}
              />

              <Popboxs
                sidebarVisible={sidebarVisible}
                toggleSidebar={toggleSidebar}
              />
              <Popboxs2
                sidebarVisible={sidebarVisibletwo}
                toggleSidebar={toggleSidebartwo}
              />
              <div
                className="d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <Sort
                  setPageSize={setPageSize}
                  setCurrentPage={setCurrentPage}
                  pageSize={pageSize}
                />
                <Pagination
                  setCurrentPage={setCurrentPage}
                  data={tabledata}
                  pageSize={pageSize}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Userlist;
