import {useNavigate } from "react-router-dom";
import { useState } from "react";
import Popboxs from "./Popboxs";
import Popboxs2 from "./Popboxs2";
import Loadercomp from "../../../components/Loadercomp";
import Sort from "../../../components/List/Sort";
import Table from "../../../components/List/Table";
import Pagination from "../../../components/List/Pagination";
import Filter from "../../../components/List/Filter";
import Convertdate from "../../../components/List/Convertdate";
import { useGetHappylistQuery } from "../../../store/api/happyapi";

const Happylist = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortby, setsortby] = useState('desc');
  const [filterValues, setFilterValues] = useState({});
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [sidebarVisibletwo, setSidebarVisibletwo] = useState(false);
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };
  const toggleSidebartwo = () => {
    setSidebarVisibletwo(!sidebarVisibletwo);
  };

  const sortfun = () =>{
   if(sortby == "desc"){
     setsortby("asc");
    }else{
     setsortby("desc");
   }
  }

  const headfields = {
    0: { label: "S.NO", key: "serial_no", increameant: true },
    1: { label: "Title", key: "title", },
    3: { label: "Total Likes", key: "total_likes", sortfunction:sortfun,sortby:sortby  },
    4: { label: "Publish by", key: "publish_by" },
    5: { label: "Created Date & Time", key: "updated_date", formatter: Convertdate },
  }

  const action = [{ icon: "view", url: "/view-happy" }];
  const filters = {
    0: { key: "title", type: "text", placeholder: "Title" },
    2: { key: "publish_by", type: "text", placeholder: "Publish by" },
    3: { key: "create_date", type: "date", placeholder: "Created At" }
  }

  const filterComponents = Object.values(filters).map((filter) => ({ ...filter, value: filterValues[filter.key] || "", }));
  const handleFilterChange = (key, value) => { setFilterValues((prev) => ({ ...prev, [key]: value })); };

  const queryParameters = Object.values(filters).reduce((acc, filter) => {
    acc[filter.key] = filterValues[filter.key] || "";
    return acc;
  }, {});

  const { data: tabledata, error, isLoading } = useGetHappylistQuery({
    page: currentPage,
  page_size:pageSize,
  sortby,
    ...queryParameters
  });

  return (
    <div className="minheight" style={{ width: "100%", minHeight: "100vh" }}>
      <div className="dashboardcontent px-2">
        <div className="container-fluid px-2">
          <div className="row bg-white py-3 rounded-top">
            <div className="col-lg-3 d-flex justify-content-between">
              <p className="m-0 customfont teenheadertitle">Happy List</p>
            </div>
            <div
              className="col-lg-9 d-flex justify-content-end align-items-center"
              style={{ gap: "7px" }}
            >
              <div
                className="addnew d-lg-flex d-none"
                style={{ flexDirection: "column", gap: "4px" }}
              >
              </div>
            </div>
          </div>

          {isLoading == true ? <>
            <div className="loading d-flex justify-content-center">
              <Loadercomp /> </div></> : <div
                className="row bg-white pb-4 rounded-bottom table-responsive"
                style={{ paddingBottom: "7rem" }}
              >
            <Filter
              filters={filterComponents}
              onFilterChange={handleFilterChange}
            />
            <Table
              headfields={headfields}
              action={action}
              // custom_date={custom_date}
              tabledata={tabledata.results}
            />

            <Popboxs
              sidebarVisible={sidebarVisible}
              toggleSidebar={toggleSidebar}
            />
            <Popboxs2
              sidebarVisible={sidebarVisibletwo}
              toggleSidebar={toggleSidebartwo}
            />
            <div
              className="d-flex"
              style={{ justifyContent: "space-between" }}
            >
              <Sort
                setPageSize={setPageSize}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
              />
              <Pagination
                setCurrentPage={setCurrentPage}
                data={tabledata}
                pageSize={pageSize}
              />
            </div>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default Happylist;