const Filter = ({ filters, onFilterChange }) => {
  const handleChange = (key, value) => {
    onFilterChange(key, value);
  };

  return (
    <div className="d-flex flex-wrap justify-content-end align-items-start gap-3">
      {filters.map((filter) => (
        <div key={filter.key} style={{ flex: 1 }}>
          <label
            htmlFor={filter.key}
            style={{ fontSize: "13px",position:'relative',left:'1px', display: "block" }}
          >
            {filter.placeholder}
          </label>
          {filter.type === "text" && (
            <input
              id={filter.key}
              type="text"
              className="form-control"
              style={{ fontSize: "13px" }}
              placeholder={filter.placeholder}
              value={filter.value || ""}
              onChange={(e) => handleChange(filter.key, e.target.value)}
            />
          )}
          {filter.type === "number" && (
            <input
              id={filter.key}
              type="number"
              className="form-control"
              style={{ fontSize: "13px" }}
              placeholder={filter.placeholder}
              value={filter.value || ""}
              onChange={(e) => handleChange(filter.key, e.target.value)}
            />
          )}
          {filter.type === "select" && (
            <select
              id={filter.key}
              className="form-control"
              style={{ fontSize: "13px" }}
              value={filter.value || ""}
              onChange={(e) => handleChange(filter.key, e.target.value)}
            >
              <option value="">{filter.placeholder}</option>
              {filter.options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          )}
          {filter.type === "date" && (
            <input
              id={filter.key}
              type="date"
              className="form-control"
              style={{ fontSize: "13px" }}
              value={filter.value || ""}
              onChange={(e) => handleChange(filter.key, e.target.value)}
            />
          )}
          {filter.type === "datetime" && (
            <input
              id={filter.key}
              type="datetime-local"
              className="form-control"
              style={{ fontSize: "13px" }}
              value={filter.value || ""}
              onChange={(e) => handleChange(filter.key, e.target.value)}
            />  
             )}
        </div>
      ))}
    </div>
  );
};

export default Filter;
