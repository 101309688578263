const Popup = ({ title, filters, onFilterChange,defaultvalue }) => {
  console.log("check inside popup",defaultvalue)
  return (
    <div
      className="modal fade"
      id={`model12345${title.key}`}
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered ">
        <div className="modal-content">
          <div className="modal-header mod-line d-none"></div>
          <div className="modal-body">
            <div className="row gy-3 mt-2" style={{position:'relative'}}>
              <h1 className="ccedit-h" style={{ fontSize: "21px" }}>
                 {title?.label}
              </h1>
              <p className="closecustomebtn" data-bs-dismiss="modal">x</p>

              {Object.values(filters).map((filter) => (
                <div>
                    <label htmlFor="">{filter.placeholder}</label>
                {filter.type === "text" && (
                    <p className="ccedit-p" style={{ marginBottom: "0px" }}>
                      <input
                        id={filter.key}
                        type="text"
                        className="form-control"
                        style={{ fontSize: "13px" }}
                        placeholder={filter.placeholder}
                        value={"" || defaultvalue[filter.key]}
                        onChange={(e) =>
                          onFilterChange(filter.key, e.target.value)
                        }
                      />
                    </p>
                  )}

                {
                  filter.type === "number" && (
                    <p className="ccedit-p" style={{marginBottom:'0px'}}>
                      <input
                        id={filter.key}
                        type="number"
                        className="form-control"
                        style={{ fontSize: "13px" }}
                        placeholder={filter.placeholder}
                        value={"" || defaultvalue[filter.key]}
                        onChange={(e) =>
                          onFilterChange(filter.key, e.target.value)
                        }
                      />
                    </p>
                  )
                }
                {filter.type === "select" && (
                    <p className="ccedit-p" style={{marginBottom:'0px'}}>
                      <select
                        id={filter.key}
                        className="form-control"
                        style={{ fontSize: "13px" }}
                        value={"" || defaultvalue[filter.key]}
                        onChange={(e) =>
                          onFilterChange(filter.key, e.target.value)
                        }
                      >
                        <option value="">{filter.placeholder}</option>
                        {filter.options.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </p>
                  )}
                {
                  filter.type === "date" && (
                    <p className="ccedit-p" style={{marginBottom:'0px'}}>
                      <input
                        id={filter.key}
                        type="date"
                        className="form-control"
                        style={{ fontSize: "13px" }}
                        value={"" || defaultvalue[filter.key]}
                        onChange={(e) =>
                          onFilterChange(filter.key, e.target.value)
                        }
                      />
                    </p>
                  )
                }
                {
                  filter.type === "datetime" && (
                    <p className="ccedit-p" style={{marginBottom:'0px'}}>
                      <input
                        id={filter.key}
                        type="datetime-local"
                        className="form-control"
                        style={{ fontSize: "13px" }}
                        value={"" || defaultvalue[filter.key]}
                        onChange={(e) =>
                          onFilterChange(filter.key, e.target.value)
                        }
                      />
                    </p>
                  )
                }
              </div>
              ))}
            </div>
          </div>

            <div className="modal-footer mod-line m-auto">

            <button
              className="btn closebtn text-white"
              type="button"
              data-bs-dismiss="modal"
              
              style={{ fontSize: "14px" }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
