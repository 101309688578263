import img from "../assets/qwerty.png";
import { FaBell, FaCog, FaEuroSign, FaUserAlt } from "react-icons/fa";
import { removeToken } from "../Localstorage/Store";
import { NavLink, useNavigate } from "react-router-dom";
import { AiOutlineDown } from "react-icons/ai";

const Header = () => {
  const nvg = useNavigate();
  const logoutevt = async () => {
    removeToken();
    nvg("/");
  };

  return (
    <div
      className="header"
      style={{
        display: "flex",
        justifyContent: "end",
        padding: "0px",
      }}
    >
      <div style={{ display: "flex" }}>
        <div className="icongroup">
          <div className="icon white">
            <div className="btn-group">
              <button
                type="button"
                className="btn dropdown-toggle-split"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <FaBell color="white" size="19px" />
              </button>
              <ul className="dropdown-menu">
                <div className="notification">
                  <h6>Emails</h6>
                  <h6 className="ms-auto">Clear All</h6>
                </div>
                <hr />
                <div className="col drop-msg d-flex align-items-start col-12">
                  <div className="col-3 d-flex justify-content-center align-items-center">
                    <img src={img} alt="" />
                  </div>
                  <div className="col-9">
                    <h6 className="noti-h">Ajay singh</h6>
                    <h6 className="noti">
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    </h6>
                  </div>
                </div>
                <hr />
                <div className="col drop-msg d-flex align-items-start col-12">
                  <div className="col-3 d-flex justify-content-center align-items-center">
                    <img src={img} alt="" />
                  </div>
                  <div className="col-9">
                    <h6 className="noti-h">Ajay singh</h6>
                    <h6 className="noti">
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    </h6>
                  </div>
                </div>
                <div className="">
                  
                  <hr />
                  <h6 className="text-center">View All</h6>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <div className="userlogo">
          <img src={img} alt="qwerty" />
        </div>
        <div className="sec-center">
          <input
            className="dropdown"
            type="checkbox"
            id="dropdown"
            name="dropdown"
          />
          <label className="for-dropdown" htmlFor="dropdown">
            Ajay Singh
            <AiOutlineDown />
          </label>
          <div className="section-dropdown">
            <div
              className="col sec-profile d-flex align-items-center justify-content-center mt-2 ms-1 col-12"
              style={{ flexDirection: "column" }}
            >
              <div className="col">
                <img src={img} alt="" />
              </div>
              <div className="col-12 name-drop">
                <p className="head-txt">Ajay Singh</p>
                <p className="head-para">azy6049@gmail.com</p>
              </div>
            </div>
            <ul className="p-0">
              <li>
                <NavLink to="/profiledetail">
                  <FaUserAlt /> <span>User Profile</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/change-password">
                  <FaCog /> <span>Change Password</span>
                </NavLink>
              </li>
              <li onClick={logoutevt}>
                <NavLink to="#">
                  <FaEuroSign /> <span>Log Out</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
