import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('token');
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && (result.error.status === 401 || result.error.status === 403)) {

    localStorage.removeItem('token');

  }

  return result;
};


export const contentApi = createApi({
  reducerPath: 'contentApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getDynamiccontentlist: builder.query({
      query: ({ page = 1, pageSize = 10, searchTerm = '' }) => ({
        url: `/dynamic-content/dynamic-contents/`,
        params: {
          page,
          page_size: pageSize,
          category: searchTerm,  // Assuming your API filters by category or search term
        },
      }),
    }),
    getDoctordetail: builder.query({
      query: ({id}) => ({
        url: `/doc-module/doc-profile/${id}`,
       method:'GET'
      }),
    }),
    postVoiceoftheday: builder.mutation({
      query: (data) => ({
        url: `/voice-day/voice-create/`,
        method:'POST',
        body:data
      })
    }),
    putVoiceoftheday: builder.mutation({
      query: ({data,id}) => ({
        url: `/voice-day/voice-update/${id}/`,
        method:'PUT',
        body:data
      })
    }),
  }),
})

export const { useGetDynamiccontentlistQuery,useGetDoctordetailQuery,usePostVoiceofthedayMutation,usePutVoiceofthedayMutation } = contentApi