import { BsFillEyeFill } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";


const itterFunc = (item, header) => {
  let key = header['key']
  let value = item
  let key_array = key.split('.')

  for (let i = 0; i < key_array.length; i++) {
   value = value[key_array[i]]
  }
  
  return header.formatter ? (
    <td>{header.formatter(value)}</td>
  ) : (
    <td >{value}</td>
  );
}

const Table = ({ tabledata, headfields, action }) => {
  return (
    <>

      <table className="styled-table mt-1">
        <thead>
          <tr>
            {Object.values(headfields).map((item, index) =>   item.sortfunction ? (
              <th key={index} onClick={()=>{item.sortfunction()}}>{item.label} {item.sortby == "asc" ? <IoMdArrowDropdown /> : <IoMdArrowDropup />} </th>
            ) : (
              <th key={index}>{item.label}</th>
            ))}
            {action.length > 0 && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {tabledata?.length > 0 ? (
            tabledata.map((item, index) => (
              <tr key={index}>
                {Object.values(headfields).map((header, colIndex) => {
                  if (header?.increameant) {
                    return <td >{index + 1}</td>;
                  }
                  return itterFunc(item, header)
                })}
                {action.length > 0 && (
                  <td>
                    {action.map((icon,index) => (
                      <NavLink
                        key={index}
                        to={`${icon.url}`}
                        style={{ position: "relative", left: "14px" }}
                        state={{ data: item }}
                      >
                        <BsFillEyeFill
                          style={{ paddingRight: "5px" }}
                          fontSize={21}
                          color="#00A192"
                        />
                      </NavLink>
                    ))}
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={Object.values(headfields).length}
                style={{ textAlign: "center", fontSize: "15px" }}
              >
                <span style={{ fontSize: "14px" }}>
                  No data available in table
                </span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default Table;
