import { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

const Chart1 = ({ data,defaultvalue }) => {
  const [chartData, setChartData] = useState([]);

  function generateAllDates(year, month) {
    const dates = [];
    const lastDate = new Date(year, month + 1, 0).getDate();

    for (let day = 1; day <= lastDate; day++) {
      const createdDate = new Date(year, month, day);
      dates.push(createdDate.toISOString().split("T")[0]);
    }

    return dates;
  }

  function getModifiedData(apiData, year, month) {
    const allDates = generateAllDates(year, month);
    console.log("check all date",allDates,year,month);
    return allDates.map((date, index) => {
      const dataForDate = apiData.find((item) => item.created_date === date);
      return {
        day: index + 1,
        created_date: date,
        user_count: dataForDate ? dataForDate.user_count : 0,
      };
    });
  }

  useEffect(() => {
    const modifiedData = getModifiedData(data, defaultvalue.year, defaultvalue.month-1);
    setChartData(modifiedData);
  }, [data, defaultvalue]);

  return (
    <div
      className="col-lg-6 col-md-12 dbox"
      style={{backgroundColor: "#efefef" }}
    >
      <div className="row rounded bg-white mt-3">
        <div className="col-12 d-flex justify-content-between align-items-center">
          <div
            className="dtext py-2"
            style={{ fontSize: "18px", color: "#62153c" }}
          >
            User
          </div>
          <div>
            <BsThreeDotsVertical
              data-bs-toggle="modal"
              data-bs-target={`#model123452`}
            />
          </div>
        </div>
        <div className="col-12 px-2">
          <div className="underline"></div>
        </div>
        <div className="col-12 py-3">
          <ResponsiveContainer width="100%" aspect={2}>
            <LineChart
              data={chartData}
              margin={{ top: 2, right: 39, bottom: 0, left: 0 }}
            >
              <CartesianGrid />
              <XAxis dataKey="day" />
              <YAxis dataKey="user_count" />
              <Tooltip
                contentStyle={{
                  background: "#00A192",
                  border: "1px solid",
                  outline: "none",
                }}
              />
              <Line dataKey="user_count" stroke="#00A192" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default Chart1;
