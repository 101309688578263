import { NavLink, useLocation } from "react-router-dom";
import { MenuItem, SubMenu } from "react-pro-sidebar";

const GlobalMenuItem = ({
  icon,
  label,
  to,
  userTypeCondition = true,
  subMenuItems = [],
  isSubMenu = false,
  openSubMenu,
  handleSubMenuClick,
  subMenuKey,
  design = {},
  activeUrls = [], 
  fun 
}) => {
  const location = useLocation(); 

  const isActive2 = activeUrls.includes(location.pathname);

  if (!userTypeCondition) return null;

  return isSubMenu ? (
    <SubMenu
      title={label}
      label={label}
      className="nothover teenfontsize"
      key={subMenuKey}
      open={openSubMenu === subMenuKey}
      onClick={() => handleSubMenuClick(subMenuKey)}
      icon={icon}
    >
      {subMenuItems.map((item, index) => (
        <GlobalMenuItem key={index} {...item} activeUrls={activeUrls} />
      ))}
    </SubMenu>
  ) : (
    <NavLink
      to={to}
      className={({ isActive }) =>
        isActive || isActive2 ? "nav active" : "nav"
      }
      onClick={fun ? fun : ''}
    >
      <MenuItem className="nothover teenfontsize" style={design} icon={icon}>
        {label}
      </MenuItem>
    </NavLink>
  );
};

export default GlobalMenuItem;
