const Sort = ({ setPageSize, setCurrentPage, pageSize }) => {
  const handlePageSizeChange = (e) => {
    const size = e.target.value;
    setPageSize(size);
    setCurrentPage(1);
  };
  return (
    <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
      Show
      <select
        value={pageSize}
        style={{ outline: "none" }}
        onChange={handlePageSizeChange}
        className="page-size-selector"
      >
        <option value={5}>5</option>
        <option value={10}>10</option>
        <option value={20}>20</option>
      </select>{" "}
      entries
    </div>
  );
};

export default Sort;
