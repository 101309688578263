import {Sidebar,Menu,MenuItem,useProSidebar} from "react-pro-sidebar";
import { gettoken, gettypeofuser, removeToken } from "../../Localstorage/Store";
import {useNavigate } from "react-router-dom";
import img from "../../assets/teenlogo.jpeg";
import img1 from "../../assets/dashboard.png";
import img2 from "../../assets/content.png";
import img3 from "../../assets/expert.png";
import img4 from "../../assets/icons10.png";
import img9 from "../../assets/logout 2.png";
import img11 from "../../assets/social-media.png";
import img111 from "../../assets/analytics.png";
import img115 from "../../assets/energy-systemold.png";
import voice from "../../assets/voice.png";
import doctor from "../../assets/doctor.png";
import happy from "../../assets/happy.png";
import { GiHamburgerMenu } from "react-icons/gi";
import appointment from "../../assets/appointment.png";
import avaliable from "../../assets/avaliable.png";
import img112 from "../../assets/financial-reporting.png";
import img113 from "../../assets/settings.png";
import img116 from "../../assets/customer-service.png";
import img117 from "../../assets/Lotus.png";
import img118 from "../../assets/logo3.png";
import { useState } from "react";
import Header from "../Header";
import GlobalMenuItem from "./GlobalMenuItem";

const Sidebarmenu = ({ children }) => {
  const nvg = useNavigate();
  const token = gettoken();
  const usertype = gettypeofuser();
  const [hideimg, sethideimg] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const { collapseSidebar } = useProSidebar();

  const logoutevt = async () => {
    removeToken();
    nvg("/");
  };

  const handleSubMenuClick = (key) => {
    setOpenSubMenu(key === openSubMenu ? null : key);
  };

  if (!token) {
    return <div style={{ background: "#F3F6FA" }}>{children}</div>;
  } else {
    return (
      <div style={{ display: "flex", width: "100%" }}>
        <Sidebar className="sidebarcum" defaultCollapsed="close">
          <div>
            <Menu className="nothover teenfontsize abc">
              <MenuItem
                className="nothover teenfontsize abc"
                style={{ borderBottom: "1px solid #D9D9D9" }}
                icon={
                  hideimg ? (
                    <GiHamburgerMenu
                      children="logobtn"
                      fontSize={23}
                      onClick={() => {
                        collapseSidebar();
                        sethideimg(!hideimg);
                      }}
                      color="#00A192"
                    />
                  ) : (
                    <img
                      src={img118}
                      onClick={() => {
                        collapseSidebar();
                        sethideimg(!hideimg);
                      }}
                      alt="404"
                    />
                  )
                }
              >
                {hideimg === true ? (
                  <img src={img} alt="qwerty" style={{ width: "99%" }} />
                ) : (
                  ""
                )}
              </MenuItem>
            </Menu>
            <Menu>
              <GlobalMenuItem
                icon={<img src={img1} alt="dashboard-icon" />}
                label="Dashboard"
                to="/dashboard"
                userTypeCondition={usertype !== "DOC login"}
              />
              <GlobalMenuItem
                icon={<img src={img4} alt="user-icon" />}
                label="User Management"
                to="/userlist"
                userTypeCondition={usertype !== "DOC login"}
                activeUrls={["/view-user"]}
              />
              <GlobalMenuItem
                icon={
                  <img src={doctor}
                    alt="doctor-icon"
                    style={{ height: "28px", width: "28px" }}
                  />
                }
                label="Doctor Management"
                to="/doctorlist"
                userTypeCondition={usertype !== "DOC login"}
                activeUrls={["/view-doctor", "add-doctor"]}
              />
              <GlobalMenuItem
                icon={
                  <img
                    src={avaliable}
                    alt="doctor-icon"
                    style={{ height: "28px", width: "28px" }}
                  />
                }
                label="Available Time"
                to="/available-time-list"
                userTypeCondition={usertype === "DOC login"}
                activeUrls={["/add-available-time"]}
              />
              <GlobalMenuItem
                icon={
                  <img
                    src={appointment}
                    alt="appointment-icon"
                    style={{ height: "28px", width: "28px" }}
                  />
                }
                label="Appointment Management"
                to="/appointment-list"
                userTypeCondition={usertype === "DOC login"}
                activeUrls={["/appointmentlistview"]}
              />
              <GlobalMenuItem
                icon={
                  <img
                    src={happy}
                    alt="happy-icon"
                    style={{ height: "28px", width: "28px" }}
                  />
                }
                label="Happy Moments"
                to="/happy"
                userTypeCondition={usertype !== "DOC login"}
              />

              <GlobalMenuItem
                icon={<img src={img2} alt="submenu-icon" />}
                label="Content Management"
                isSubMenu={true}
                subMenuKey="Content Management"
                openSubMenu={openSubMenu}
                userTypeCondition={usertype !== "DOC login"}
                handleSubMenuClick={handleSubMenuClick}
                subMenuItems={[
                  {
                    icon: <img src={img117} alt="meditation-icon" />,
                    label: "Meditation",
                    to: "/meditationlist",
                    userTypeCondition: usertype !== "DOC login",
                    design: { paddingLeft: "35px" },
                  },
                  {
                    icon: (
                      <img
                        src={voice}
                        alt="voice-of-the-day-icon"
                        style={{ height: "28px", width: "28px" }}
                      />
                    ),
                    label: "Voice of the Day",
                    to: "/voice-of-the-day-list",
                    userTypeCondition: usertype !== "DOC login",
                    design: { paddingLeft: "35px" },
                  },
                  {
                    icon: <img src={img2} alt="dynamic-contents-icon" />,
                    label: "Dynamic Content",
                    to: "/dynamic-contents-list",
                    userTypeCondition: usertype !== "DOC login",
                    design: { paddingLeft: "35px" },
                  },
                ]}
              />
              <GlobalMenuItem
                icon={<img src={img111} alt="submenu-icon" />}
                label="Reports"
                isSubMenu={true}
                subMenuKey="Reports"
                userTypeCondition={usertype !== "DOC login"}
                openSubMenu={openSubMenu}
                handleSubMenuClick={handleSubMenuClick}
                subMenuItems={[
                  {
                    icon: <img src={img111} alt="reports-icon" />,
                    label: "Reports & Analytics",
                    to: "/addagency",
                    userTypeCondition: usertype !== "DOC login",
                    design: { paddingLeft: "35px" },
                  },
                  {
                    icon: <img src={img112} alt="financial-reports-icon" />,
                    label: "Financial Reports",
                    to: "/addagency",
                    userTypeCondition: usertype !== "DOC login",
                    design: { paddingLeft: "35px" },
                  },
                ]}
              />

              <GlobalMenuItem
                icon={
                  <img
                    src={img3}
                    alt="expert-icon"
                    style={{ height: "28px", width: "28px" }}
                  />
                }
                label="Expert Management"
                to="/addcorporate"
                userTypeCondition={usertype !== "DOC login"}
              />
              <GlobalMenuItem
                icon={
                  <img
                    src={img11}
                    alt="media-icon"
                    style={{ height: "28px", width: "28px" }}
                  />
                }
                label="Media Management"
                to="/page"
                userTypeCondition={usertype !== "DOC login"}
              />
              <GlobalMenuItem
                icon={
                  <img
                    src={img113}
                    alt="setting-icon"
                    style={{ height: "28px", width: "28px" }}
                  />
                }
                label="Settings & Configurations"
                to="/page2"
                userTypeCondition={usertype !== "DOC login"}
              />
              <GlobalMenuItem
                icon={
                  <img
                    src={img116}
                    alt="support-icon"
                    style={{ height: "28px", width: "28px" }}
                  />
                }
                label="Support & Help"
                to="/page3"
                userTypeCondition={usertype !== "DOC login"}
              />
              <GlobalMenuItem
                icon={
                  <img
                    src={img115}
                    alt="integration-icon"
                    style={{ height: "28px", width: "28px" }}
                  />
                }
                label="Integration Management"
                to="/agencylist"
                userTypeCondition={usertype !== "DOC login"}
              />
              <GlobalMenuItem
                icon={
                  <img
                    src={img9}
                    alt="logout-icon"
                    style={{ height: "28px", width: "28px" }}
                  />
                }
                label="Log Out"
                to="/"
                userTypeCondition={true}
                fun={logoutevt}
              />
 
            </Menu>
          </div>
        </Sidebar>
        <div style={{ width: "100%" }}>
          <Header />
          {children}
        </div>
      </div>
    );
  }
};

export default Sidebarmenu;
