import { useEffect, useState } from "react";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useLocation, useNavigate } from "react-router-dom";
import Loadercomp from "../../../components/Loadercomp";
import Breadcup from "../../../components/Breadcup";

const Appointmentlistview = () => {
    const location = useLocation();
    const nvg = useNavigate();
    const [data, setdata] = useState({});
    const [srtloader, setsrtloader] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    useEffect(() => {
        if (location.state.data) {
            setdata(location.state.data);
            console.log("qwertyuioppoiuytrew",location.state.data);
            setsrtloader(false);
        }
    }, [location]);

    return (
        <div style={{ width: "100%", minHeight: "100vh" }}>
            <div className="dashboardcontent px-2">
                <Breadcup name={"Appointment List"} secondname={"Appointment Details"} />
                <div
                    className="container-fuild px-2 mb-1"
                    style={{ overflow: "hidden" }}
                >
                    <div className="row bg-white ">
                        <div
                            className="col-lg-12 d-flex justify-content-between py-2"
                            style={{ color: "#0C5398" }}
                        >
                            <p
                                className="m-0 customfont teenheadertitle py-2"
                                style={{ color: "#62153C" }}
                            >
                                Appointment Details
                            </p>
                            <div className="addnew d-block mb-2">
                                <button
                                    className="btn "
                                    type="button"
                                    onClick={() => {
                                        nvg("/appointment-list");
                                    }}
                                    style={{
                                        outline: "none",
                                        background: "white",
                                        color: "#00A192",
                                        fontSize: "19px",
                                        fontWeight: "550",
                                    }}
                                >
                                    {" "}
                                    X{" "}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {srtloader === true ? (
                    <div className="container-fuild bg-white">
                        {" "}
                        <div
                            className="col-12 d-flex justify-content-center"
                            style={{ gap: "4px", position: "absolute", width: "70%" }}
                        >
                            <div className="loading d-flex justify-content-center">
                                {" "}
                                <Loadercomp size={100} />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="container-fuild pb-4 pt-3 px-2 bg-white">
                        <div
                            className="row bg-white round"
                            style={{
                                border: "1px solid #E0E0E0",
                                margin: "10px 0px",
                                borderRadius: "3px",
                            }}
                        >
                            <div className="col-lg-6 px-4 pt-3">
                                <div className="row">
                                    <div className="col-md-3 col-4 d-flex align-items-center">
                                        <label htmlFor="" className="form-label teenfontsize m-0">
                                            UserName
                                        </label>
                                    </div>
                                    <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                                        <input
                                            type="text"
                                            className="form-control teenfontsize2"
                                            value={data.nickname}
                                            readOnly
                                        
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 px-4 pt-3">
                                <div className="row">
                                    <div className="col-md-3 col-4 d-flex align-items-center">
                                        <label
                                            htmlFor=""
                                            className="form-label teenfontsize customw m-0"
                                        >
                                            Doctor Name
                                        </label>
                                    </div>
                                    <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                                        <input
                                            type="text"
                                            className="form-control teenfontsize2"
                                            value={data.doctor_name}
                                            readOnly
                                           
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 px-4 pt-3">
                                <div className="row">
                                    <div className="col-md-3 col-4 d-flex align-items-center">
                                        <label
                                            htmlFor=""
                                            className="form-label teenfontsize customw m-0"
                                        >
                                            Age
                                        </label>
                                    </div>
                                    <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                                        <input
                                            type="text"
                                            className="form-control teenfontsize2"
                                            value={`${new Date().getFullYear() - new Date(data.date_of_birth).getFullYear() - (new Date() < new Date(new Date(data.date_of_birth).setFullYear(new Date().getFullYear())) ? 1 : 0)} years ${new Date().getMonth() - new Date(data.date_of_birth).getMonth() + (new Date().getDate() < new Date(data.date_of_birth).getDate() ? -1 : 0) + (new Date().getMonth() - new Date(data.date_of_birth).getMonth() < 0 ? 12 : 0)} months`}
                                            readOnly
                                           
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 px-4 pt-3">
                                <div className="row">
                                    <div className="col-md-3 col-4 d-flex align-items-center">
                                        <label
                                            htmlFor=""
                                            className="form-label teenfontsize customw m-0"
                                        >
                                            Status
                                        </label>
                                    </div>
                                    <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                                        <input
                                            type="text"
                                            className="form-control teenfontsize2"
                                            value={data.payment_status}
                                            readOnly
                                            
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 px-4 pt-3">
                                <div className="row">
                                    <div className="col-md-3 col-4 d-flex align-items-center">
                                        <label
                                            htmlFor=""
                                            className="form-label teenfontsize customw m-0"
                                        >
                                            From 
                                        </label>
                                    </div>
                                    <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                                        <input
                                            type="text"
                                            className="form-control teenfontsize2"
                                            value={new Date(data.from_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}
                                            readOnly
                                        
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 px-4 pt-3">
                                <div className="row">
                                    <div className="col-md-3 col-4 d-flex align-items-center">
                                        <label
                                            htmlFor=""
                                            className="form-label teenfontsize customw m-0"
                                        >
                                            To
                                        </label>
                                    </div>
                                    <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                                        <input
                                            type="text"
                                            className="form-control teenfontsize2"
                                            value={new Date(data.to_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}
                                            readOnly
                                           
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 px-4 pt-3">
                                <div className="row">
                                    <div className="col-md-3 col-4 d-flex align-items-center">
                                        <label
                                            htmlFor=""
                                            className="form-label teenfontsize customw m-0"
                                        >
                                            Gender
                                        </label>
                                    </div>
                                    <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                                        <input
                                            type="text"
                                            className="form-control teenfontsize2"
                                            value={data.gender}
                                            readOnly
                                       
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 px-4 pt-3">
                                <div className="row">
                                    <div className="col-md-3 col-4 d-flex align-items-center">
                                        <label
                                            htmlFor=""
                                            className="form-label teenfontsize customw m-0"
                                        >
                                            Amount
                                        </label>
                                    </div>
                                    <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                                        <input
                                            type="text"
                                            className="form-control teenfontsize2"
                                            value={data.price}
                                            readOnly
                                            
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 px-4 pt-3">
                                <div className="row">
                                    <div className="col-md-3 col-4 d-flex align-items-center">
                                        <label
                                            htmlFor=""
                                            className="form-label teenfontsize customw m-0"
                                        >
                                            DOB
                                        </label>
                                    </div>
                                    <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                                        <input
                                            type="text"
                                            className="form-control teenfontsize2"
                                            value={data.date_of_birth}
                                            readOnly
                                            
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 px-4 pt-3 pb-4">
                                <div className="row">
                                    <div className="col-md-3 col-4 d-flex align-items-center">
                                        <label htmlFor="" className="form-label teenfontsize m-0">
                                            Uploaded At
                                        </label>
                                    </div>
                                    <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                                        <input
                                            type="text"
                                            className="form-control teenfontsize2"
                                            value={new Date(data.created_date).toISOString().split("T")[0]}
                                             readOnly
                                        
                                        />
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Appointmentlistview;