import ReactPaginate from 'react-paginate';

const Pagination = ({setCurrentPage,data,pageSize}) => {

    const handlePageChange = (selectedPage) => {
        const page = selectedPage.selected + 1;
        setCurrentPage(page);
      };
  return (
    <>
    <ReactPaginate
          previousLabel={"previous"}
          nextLabel={"next"}
          breakLabel={"..."}
          pageCount={Math.ceil(data?.count / pageSize)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
    </>
  )
}

export default Pagination