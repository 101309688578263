import img1 from "../../assets/Ellipse 27.png";
import img3 from "../../assets/Ellipse 29.png";
import img5 from "../../assets/money.png";
import Loadercomp from "../../components/Loadercomp";
import { useGetDashboardDataQuery } from "../../store/api/adminapi";
import Countbox from "./components/Countbox";
import Chart1 from "./components/Chart1";
import Chart2 from "./components/Chart2";
import { useEffect, useState } from "react";
import Popup from "./components/Popup";
const Dashboard = () => {
  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);
  const currentDate = new Date();
  const currentMonth = (currentDate.getMonth() + 1).toString().padStart(1, "0");
  const currentYear = currentDate.getFullYear().toString();
  const [filterValues, setFilterValues] = useState({
    month: currentMonth,
    year: currentYear,
    designation: "all",
    appointment_month: currentMonth,
    appointment_year: currentYear,
  });


  const { data: dashboarddata, isLoading } = useGetDashboardDataQuery({
    ...filterValues,
  });
  const handleFilterChange = (key, value) => {
    setFilterValues((prev) => ({ ...prev, [key]: value }));
  };
  useEffect(() => {
    const monthNames = [
      { value: 1, label: "January" },
      { value: 2, label: "February" },
      { value: 3, label: "March" },
      { value: 4, label: "April" },
      { value: 5, label: "May" },
      { value: 6, label: "June" },
      { value: 7, label: "July" },
      { value: 8, label: "August" },
      { value: 9, label: "September" },
      { value: 10, label: "October" },
      { value: 11, label: "November" },
      { value: 12, label: "December" },
    ];
    setMonths(monthNames);
    const currentYear = new Date().getFullYear();
    const lastTenYears = Array.from({ length: 10 }, (_, i) => {
      const year = currentYear - i;
      return { value: year, label: `${year}` };
    });
    setYears(lastTenYears);
  }, []);


  const filters = {
    0: {
      key: "month",
      type: "select",
      placeholder: "Select Month",
      options: months,
    },
    1: {
      key: "year",
      type: "select",
      placeholder: "Select Year",
      options: years,
    },
    2: {
      key: "designation",
      type: "select",
      placeholder: "Designation",
      options: [
        { value: "all", label: "all" },
        { value: "doc", label: "Doctor" },
        { value: "teens", label: "Teen" },
        { value: "parents", label: "Parent" },
      ],
    },
  };
  const filters2 = {
    0: {
      key: "appointment_month",
      type: "select",
      placeholder: "Select Month",
      options: months,
    },
    1: {
      key: "appointment_year",
      type: "select",
      placeholder: "Select Year",
      options: years,
    },
  };
  const teenfilters = {
    0: { key: "teen_startdate", type: "date", placeholder: "Start Date" },
    1: { key: "teen_enddate", type: "date", placeholder: "End Date" },
  };
  const parentfilters = {
    0: { key: "parent_startdate", type: "date", placeholder: "Start Date" },
    1: { key: "parent_enddate", type: "date", placeholder: "End Date" },
  };
  const docfilters = {
    0: { key: "doc_startdate", type: "date", placeholder: "Start Date" },
    1: { key: "doc_enddate", type: "date", placeholder: "End Date" },
  };

  return (
    <div style={{ width: "100%", minHeight: "100vh" }}>
      <div className="dashboardcontent">
        <div className="dashboard" style={{ width: "100%" }}>
          <div
            className="row d-flex justify-content-between"
            style={{ padding: "0px 14px" }}
          >
            <div className="col-6">
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "22px",
                  margin: "0px",
                  color: "#62153C",
                }}
              >
                Dashboard
              </p>
            </div>
          </div>
        </div>

        {isLoading == true ? (
          <div className="container-fuild bg-white">
             <div
              className="col-12 d-flex justify-content-center"
              style={{ gap: "4px", position: "absolute", width: "100%" }}
            >
              <div className="loading d-flex justify-content-center">
                 <Loadercomp size={100} />
              </div>
            </div>
          </div>
        ) : (
          <div
            className="container-fuild py-4"
            style={{ paddingLeft: "0px", paddingRight: "0px" }}
          >
            <div className="row" style={{ padding: "0px 14px" }}>
              <Countbox
                count={dashboarddata.total_teens}
                title={{ label: "Total Teens", key: "teen" }}
                img1={img1}
                defaultvalue={filterValues}
                onFilterChange={handleFilterChange}
                filterfields={teenfilters}
              />
              <Countbox
                count={dashboarddata.total_parents}
                title={{ label: "Total Parent", key: "parent" }}
                img1={img3}
                defaultvalue={filterValues}
                onFilterChange={handleFilterChange}
                filterfields={parentfilters}
              />
              <Countbox
                count={dashboarddata.total_doctors}
                title={{ label: "Total Doctor", key: "doc" }}
                img1={img5}
                defaultvalue={filterValues}
                onFilterChange={handleFilterChange}
                filterfields={docfilters}
              />
              <div className="mt-3 p-0 d-flex">
                <Chart1 data={dashboarddata.daily_registration_data} defaultvalue={filterValues} />

                <Chart2 data={dashboarddata.daily_appointment_data} defaultvalue={filterValues} />
              </div>
            </div>
          </div>
        )}
      </div>
      <Popup
        title={{ label: "Filter by Month and Year", key: "2" }}
        filters={filters}
        onFilterChange={handleFilterChange}
        defaultvalue={filterValues}
      />
      <Popup
        title={{ label: "Filter by Month and Year", key: "12" }}
        filters={filters2}
        onFilterChange={handleFilterChange}
        defaultvalue={filterValues}
      />
    </div>
  );
};

export default Dashboard;
