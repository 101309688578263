import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem("token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (
    result.error &&
    (result.error.status === 401 || result.error.status === 403)
  ) {
    localStorage.removeItem("token");
  }

  return result;
};


export const adminApi = createApi({
  reducerPath: 'adminApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getDashboardData: builder.query({
      query: (param) => ({
        url: `/user/dashboard`,
        params:param
      }),
    }),
    postLogin: builder.mutation({
      query: (data) => ({
        url: `/user/admin_login/`,
        method:'POST',
        body:data
      })
    }),
  }),
})

export const { usePostLoginMutation, useGetDashboardDataQuery } = adminApi