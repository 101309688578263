import React, { useState } from "react";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import Loadercomp from "../../../components/Loadercomp";
import Breadcup from "../../../components/Breadcup";
import { getcurrentuserid } from "../../../Localstorage/Store";
import { usePostAvailableTimeMutation } from "../../../store/api/available-time-api";
import Form from "./Form";

const Addavailabletime = () => {
  const nvg = useNavigate();
  const [loading, setloading] = useState(false);
  const userid = getcurrentuserid();
  const [meditationresponse, setmeditationresponse] = useState("");
  const [meditationerror, setmeditationerror] = useState("");
  console.log("1234yuytre", meditationerror);
  const [Createavailabletime] = usePostAvailableTimeMutation();
  const [formData, setFormData] = useState({
    fromtime: null,
    totime: null,
  });

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    // const formattedValue = new Date(value).toISOString();
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("to_time", new Date(formData.totime).toISOString());
    data.append("from_time", new Date(formData.fromtime).toISOString());
    data.append("doctor_id", userid);

    const pro = Createavailabletime(data);
    setFormData({
      fromtime: null,
      totime: null,
    });
    const response = await pro;
    console.log("123456789", response);
    if (response.error) {
      setmeditationerror(response.error.data.non_field_errors[0]);
    }
  };

  return (
    <div style={{ width: "100%", minHeight: "100vh" }}>
      <div className="dashboardcontent px-2">
        <Breadcup name={"Available Time"} secondname={"Add Available Time"} />
        <div
          className="container-fuild px-2 mb-1"
          style={{ overflow: "hidden" }}
        >
          <div className="row bg-white ">
            <div
              className="col-lg-12 d-flex justify-content-between py-2"
              style={{ color: "#0C5398" }}
            >
              <p
                className="m-0 customfont teenheadertitle py-2"
                style={{ color: "#62153C" }}
              >
                Add Available Time
              </p>
              <div className="addnew d-block mb-2">
                <button
                  className="btn "
                  type="button"
                  onClick={() => {
                    nvg("/available-time-list");
                  }}
                  style={{
                    background: "white",
                    color: "#00A192",
                    fontSize: "19px",
                    fontWeight: "550",
                  }}
                >
                  {" "}
                  X{" "}
                </button>
              </div>
            </div>
          </div>
        </div>

        {loading == true ? (
          <div className="container-fuild bg-white">
            {" "}
            <div
              className="col-12 d-flex justify-content-center"
              style={{ gap: "4px", position: "absolute", width: "70%" }}
            >
              <div className="loading d-flex justify-content-center">
                {" "}
                <Loadercomp size={100} />
              </div>
            </div>
          </div>
        ) : (
          <div className="container-fuild pb-4 pt-3 px-2 bg-white">
             <Form />
          </div>
        )}

       
        
      </div>
    </div>
  );
};

export default Addavailabletime;
