import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { NavLink } from "react-router-dom";
import { useGetDoctordetailQuery } from "../../store/api/doctorapi";
import { BiEdit } from "react-icons/bi";
import Loadercomp from "../../components/Loadercomp";
const Profiledetail = () => {

  const { data: Data, error, isLoading } = useGetDoctordetailQuery({ id: "1" });

  if (isLoading) {
    return (
      <div className="loading d-flex justify-content-center">
        <Loadercomp />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center">
        <p>Error fetching doctor details.</p>
      </div>
    );
  }

  return (
    <div className="container-fluid py-4">
      <div className="dashboardcontent ">
        {/* <div className="container-fuild px-2 desgin1">
          <div className="row bg-white ">
            <div
              className="col-lg-12 d-flex justify-content-between py-2 align-items-center"
              style={{
                background: "hsla(210, 85%, 32%, 0.2)",
                color: "#0C5398",
              }}
            >
              <p className="m-0 customfont" style={{ height: "fit-content" }}>
                User Profile
              </p>

              <div className="addnew d-block ">
                <button className="btn text-white closebtn">
                  <NavLink
                    to="/dashboard"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    {" "}
                    x Close
                  </NavLink>
                </button>
              </div>
            </div>
          </div>
        </div> */}

        <div className="card" style={{ border: "none", margin: "0px 0px 15px 0px" }}>
          <div className="card-body mt-4 px-5">
            <div className="row g-2">
              {/* Profile Image */}
              <div className="col-12 d-flex justify-content-between align-items-center pb-2" style={{borderBottom: "2px solid #cac8c8",}}>
                <p
                className="m-0"
                  style={{
                    color: "#00a192",
                    fontSize: "17px",
                    fontWeight: "500",
                  }}
                >
                  Personal Information
                </p>
                <div className="addnew d-block ">
                <button className="btn text-white closebtn" style={{padding:'3px 8px'}}>
                  <NavLink
                    to="/dashboard"
                    style={{ textDecoration: "none", color: "white",fontSize:"14px",padding:"2px 1px" }}
                  >
                    {" "}
                    <BiEdit /> Edit
                  </NavLink>
                </button>
              </div>
                
              </div>
              <div
                className="col-12 d-flex align-items-center mb-3"
                style={{ gap: "18px" }}
              >
                <img
                  src={Data?.profile_pic || "/default-profile.png"}
                  alt="Doctor Profile"
                  className="img-fluid rounded-circle mb-1"
                  style={{
                    width: "160px",
                    height: "160px",
                    objectFit: "cover",
                  }}
                />
                <div>
                  <p
                    className="m-0"
                    style={{
                      color: "#00A192",
                      fontWeight: "600",
                      fontSize: "26px",
                    }}
                  >
                    {Data?.accounts?.first_name || "N/A"}{" "}
                    {Data?.accounts?.last_name || "N/A"}
                  </p>
                  <p
                    className="m-0"
                    style={{
                      color: "#00A192",
                      fontWeight: "600",
                      fontSize: "15px",
                    }}
                  >
                    {Data?.accounts?.designation === "DOC" ? "Doctor" : "Admin"}
                  </p>
                  <p
                    className="m-0"
                    style={{
                      color: "#00A192",
                      fontWeight: "600",
                      fontSize: "15px",
                    }}
                  >
                    {Data?.doctor_type || "N/A"}
                  </p>
                </div>
              </div>

              <div className="col-md-4 mb-3">
                <label
                  className="fw-semibold fs-10"
                  style={{ fontSize: "15px", color: "#817e7e" }}
                >
                  Full Name:
                </label>
                <p
                  className="fs-6"
                  style={{
                    fontSize: "15px",
                    fontWeight: "600",
                    color: "#474444",
                  }}
                >
                  {Data?.accounts?.first_name || "N/A"}{" "}
                  {Data?.accounts?.last_name || "N/A"}{" "}
                </p>
              </div>
              <div className="col-md-4 mb-3">
                <label
                  className="fw-semibold fs-10"
                  style={{ fontSize: "15px", color: "#817e7e" }}
                >
                  Email:
                </label>
                <p
                  className="fs-6"
                  style={{
                    fontSize: "15px",
                    fontWeight: "600",
                    color: "#474444",
                  }}
                >
                  {Data?.accounts?.email || "N/A"}
                </p>
              </div>
              <div className="col-md-4 mb-3">
                <label
                  className="fw-semibold fs-10"
                  style={{ fontSize: "15px", color: "#817e7e" }}
                >
                  Phone Number:
                </label>
                <p
                  className="fs-6"
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#474444",
                  }}
                >
                  {Data?.accounts?.phone_number || "N/A"}
                </p>
              </div>
              <div className="col-md-4 mb-3">
                <label
                  className="fw-semibold fs-10"
                  style={{ fontSize: "15px", color: "#817e7e" }}
                >
                  Price:
                </label>
                <p
                  className="fs-6"
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#474444",
                  }}
                >
                  {Data?.amount || "N/A"}
                </p>
              </div>
              <div
                className="col-md-4 mb-3"
                style={{ fontSize: "15px", color: "#817e7e" }}
              >
                <label className="fw-semibold fs-10">Experience:</label>
                <p
                  className="fs-6"
                  style={{
                    fontSize: "15px",
                    fontWeight: "600",
                    color: "#474444",
                  }}
                >
                  {Data?.experience || "N/A"} years
                </p>
              </div>
              <div
                className="col-md-4 mb-3"
                style={{ fontSize: "15px", color: "#817e7e" }}
              >
                <label className="fw-semibold fs-10">Doctor Type:</label>
                <p
                  className="fs-6"
                  style={{
                    fontSize: "15px",
                    fontWeight: "600",
                    color: "#474444",
                  }}
                >
                  {Data?.doctor_type || "N/A"}
                </p>
              </div>
              <div className="col-12">
                <label
                  className="fw-semibold fs-10"
                  style={{ fontSize: "15px", color: "#817e7e" }}
                >
                  About:
                </label>
                <p
                  className="fs-6"
                  style={{
                    fontSize: "15px",
                    fontWeight: "600",
                    color: "#474444",
                  }}
                >
                  {Data?.about || "No information provided."}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="card" style={{ border: 'none' }}>
        <div className="card-body px-5">
          <div className="row g-2">
            <div className="col-md-12">
              <div className="row">
                <div className="col-12">
                  <p style={{borderBottom:"2px solid #cac8c8",paddingBottom:'12px',color:'#00a192',fontSize:'17px',fontWeight:'500'}}>Personal Information</p>
                </div>
                <div className="col-md-4 mb-3">
                  <label className="fw-semibold fs-10" style={{fontSize:'15px',color:'#817e7e'}}>Email:</label>
                  <p className="fs-6" style={{fontSize:'15px',fontWeight:"600",color:"#474444"}}>{Data?.accounts?.email || 'N/A'}</p>
                </div>
                <div className="col-md-4 mb-3">
                  <label className="fw-semibold fs-10" style={{fontSize:'15px',color:'#817e7e'}}>Phone Number:</label>
                  <p className="fs-6" style={{fontSize:'14px',fontWeight:"600",color:"#474444"}}>{Data?.accounts?.phone_number || 'N/A'}</p>
                </div>
                <div className="col-md-4 mb-3">
                  <label className="fw-semibold fs-10" style={{fontSize:'15px',color:'#817e7e'}}>Price:</label>
                  <p className="fs-6" style={{fontSize:'14px',fontWeight:"600",color:"#474444"}}>{Data?.amount || 'N/A'}</p>
                </div>
                <div className="col-md-4 mb-3" style={{fontSize:'15px',color:'#817e7e'}}>
                  <label className="fw-semibold fs-10">Experience:</label>
                  <p className="fs-6" style={{fontSize:'15px',fontWeight:"600",color:"#474444"}}>{Data?.experience || 'N/A'} years</p>
                </div>
                <div className="col-md-4 mb-3" style={{fontSize:'15px',color:'#817e7e'}}>
                  <label className="fw-semibold fs-10">Doctor Type:</label>
                  <p className="fs-6" style={{fontSize:'15px',fontWeight:"600",color:"#474444"}}>{Data?.doctor_type || 'N/A'}</p>
                </div>
                <div className="col-12">
                  <label className="fw-semibold fs-10" style={{fontSize:'15px',color:'#817e7e'}}>About:</label>
                  <p className="fs-6" style={{fontSize:'15px',fontWeight:"600",color:"#474444"}}>{Data?.about || 'No information provided.'}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
       
      </div> */}

        <div className="card mt-4" style={{ border: "none" }}>
          <div className="card-body px-5">
            <div className="row g-2">
              {/* Profile Image */}

              {/* Profile Details */}
              <div className="col-md-12">
                <div className="row">
                  <div className="col-12">
                    <p
                      style={{
                        borderBottom: "2px solid #cac8c8",
                        paddingBottom: "12px",
                        color: "#00a192",
                        fontSize: "17px",
                        fontWeight: "500",
                      }}
                    >
                      Reviews
                    </p>
                  </div>

                  <div className="col-12">
                    {Data?.reviews && Data.reviews.length > 0 ? (
                      Data.reviews.map((review, index) => (
                        <div
                          key={index}
                          className="review-card mb-4 p-4 border rounded shadow-sm"
                          style={{
                            backgroundColor: "#ffffff",
                            borderLeft: "#00A192",
                            transition: "transform 0.2s ease-in-out",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          }}
                          onMouseEnter={(e) =>
                            (e.currentTarget.style.transform = "scale(1.02)")
                          }
                          onMouseLeave={(e) =>
                            (e.currentTarget.style.transform = "scale(1)")
                          }
                        >
                          <div className="d-flex align-items-center mb-3">
                            {/* Avatar */}
                            <img
                              src={review.avatar || "/default-avatar.png"}
                              alt="User Avatar"
                              className="rounded-circle"
                              style={{
                                width: "60px",
                                height: "60px",
                                objectFit: "cover",
                                marginRight: "20px",
                                border: "2px solid #00A192",
                              }}
                            />
                            {/* Username */}
                            <div>
                              <h5 className="m-0" style={{ color: "#00A192" }}>
                                {review.user || "Anonymous"}
                              </h5>
                              <small className="text-muted d-block">
                                <i className="bi bi-star-fill text-warning"></i>{" "}
                                {review.rating}/5
                              </small>
                            </div>
                          </div>

                          {/* Review Content */}
                          <blockquote
                            className="blockquote mb-0 text-secondary"
                            style={{
                              fontStyle: "italic",
                              borderLeft: "4px solid #00A192",
                              paddingLeft: "15px",
                              fontSize: "16px",
                            }}
                          >
                            "{review.review}"
                          </blockquote>
                        </div>
                      ))
                    ) : (
                      <p className="text-muted">No reviews available.</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profiledetail;
