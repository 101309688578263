import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('token');
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  console.log(result);
  

  if (result.error && (result.error.status === 401 || result.error.status === 403)) {

    // localStorage.removeItem('token');
    // window.location.href = "/";

  }

  return result;
};


export const doctorApi = createApi({
  reducerPath: 'doctorApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getDoctorlist: builder.query({
      query: (params) => ({
        url: `/doc-module/doc-list/`,
        params: params,
      }),
    }),
    getDoctordetail: builder.query({
      query: ({id}) => ({
        url: `/doc-module/doc-profile/${id}/`,
        method:'GET'
      }),
    }),
    getDoctordetaillist: builder.query({
      query: ({ page = 1, pageSize = 10, realfromtime=null,
        realtotime=null,
        createdAt=null, }) => ({
        url: `/doc-module/doc-available-list-admin`,
        params: {
          page,
          page_size: pageSize,
          created_date: createdAt,
          from:realfromtime,
          to:realtotime,

        },
      }),
    }),
    postDoctorChangePassword: builder.mutation({
      query: (data) => ({
        url: `/user/change-password/`,
        method:'POST',
        body:data
      })
    }),
    postDoctor: builder.mutation({
      query: (data) => ({
        url: `/doc-module/doc-create/`,
        method:'POST',
        body:data
      })
    }),
    putVoiceoftheday: builder.mutation({
      query: ({data,id}) => ({
        url: `/voice-day/voice-update/${id}/`,
        method:'PUT',
        body:data
      })
    }),
  }),
})

export const { useGetDoctorlistQuery,useGetDoctordetailQuery, usePostDoctorChangePasswordMutation,useGetDoctordetaillistQuery,usePostDoctorMutation,usePutVoiceofthedayMutation } = doctorApi