import { NavLink, useNavigate } from "react-router-dom";
import { useState } from "react";
import Popboxs from "./Popboxs";
import Popboxs2 from "./Popboxs2";
import Sort from "../../../components/List/Sort";
import Pagination from "../../../components/List/Pagination";
import Loadercomp from "../../../components/Loadercomp";
import Table from "../../../components/List/Table";
import Convertdate from "../../../components/List/Convertdate";
import { useGetDoctorlistQuery } from "../../../store/api/doctorapi";
import Filter from "../../../components/List/Filter";

const Doctorlist = () => {
  const nvg = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };
  const [filterValues, setFilterValues] = useState({});

  const [sidebarVisibletwo, setSidebarVisibletwo] = useState(false);
  const toggleSidebartwo = () => {
    setSidebarVisibletwo(!sidebarVisibletwo);
  };
  const headfields = {
    0: { label: "S.NO", key: "serial_no", increameant: true },
    1: {
      label: "FullName",
      key: "accounts",
      formatter: (account) => `${account.first_name} ${account.last_name}`,
    },
    3: { label: "Price", key: "amount" },
    4: { label: "Designation", key: "accounts.designation" },
    5: { label: "Email ID", key: "accounts.email" },
    7: { label: "Mobile No.", key: "accounts.phone_number" },
    8: {
      label: "Created Date & Time",
      key: "created_at",
      formatter: Convertdate,
    },
  };
  const action = [{ icon: "view", url: "/view-doctor" }];
  const filters = {
    0: { key: "desigination", type: "text", placeholder: "Desigination" },
    1: { key: "price", type: "number", placeholder: "Price" },
    2: { key: "email", type: "text", placeholder: "Email ID" },
    3: { key: "mobile", type: "number", placeholder: "Mobile No." },
    4: { key: "created_date", type: "date", placeholder: "Created At" },
  };

  const filterComponents = Object.values(filters).map((filter) => ({
    ...filter,
    value: filterValues[filter.key] || "",
  }));
  const handleFilterChange = (key, value) => {
    setFilterValues((prev) => ({ ...prev, [key]: value }));
  };

  const queryParameters = Object.values(filters).reduce((acc, filter) => {
    acc[filter.key] = filterValues[filter.key] || "";
    return acc;
  }, {});

  const { data: tabledata, isLoading } = useGetDoctorlistQuery({
    page: currentPage,
    page_size: pageSize,
    ...queryParameters,
  });
  return (
    <div className="minheight" style={{ width: "100%", minHeight: "100vh" }}>
      <div className="dashboardcontent px-2">
        <div className="container-fluid px-2">
          <div className="row bg-white py-3 rounded-top">
            <div className="col-lg-3 d-flex justify-content-between">
              <p className="m-0 customfont teenheadertitle">Doctor List</p>
            </div>
            <div className="col-lg-9 d-flex justify-content-end align-items-center" style={{ gap: "7px" }}>
              <div
                className="addnew d-lg-flex d-none"
                style={{ flexDirection: "column", gap: "4px" }}
              >
                <button
                  className="btn text-white customcolor2"
                  style={{ fontSize: "13px" }}
                  onClick={() => {
                    nvg("/add-doctor");
                  }}
                >
                  <NavLink
                    to="/add-doctor"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    + Add New
                  </NavLink>
                </button>
              </div>
            </div>
          </div>

          {isLoading === true ? (
              <div className="loading d-flex justify-content-center">
                <Loadercomp />
              </div>
          ) : (
            <div className="row bg-white pb-4 rounded-bottom table-responsive" style={{ paddingBottom: "7rem" }}>
              <Filter
                filters={filterComponents}
                onFilterChange={handleFilterChange}
              />

              <Table
                headfields={headfields}
                action={action}
                tabledata={tabledata.results}
              />

              <Popboxs
                sidebarVisible={sidebarVisible}
                toggleSidebar={toggleSidebar}
              />

              <Popboxs2
                sidebarVisible={sidebarVisibletwo}
                toggleSidebar={toggleSidebartwo}
              />
              <div className="d-flex" style={{ justifyContent: "space-between" }}>
                <Sort
                  setPageSize={setPageSize}
                  setCurrentPage={setCurrentPage}
                  pageSize={pageSize}
                />
                <Pagination
                  setCurrentPage={setCurrentPage}
                  data={tabledata}
                  pageSize={pageSize}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Doctorlist;
