import Sidebar from "./components/Sidebar/Sidebar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import Login from "./pages/Login/Login";
import { gettoken, gettypeofuser } from "./Localstorage/Store";
import Meditationlist from "./pages/Meditation/Meditationlist/Meditationlist";
import Viewmwditation from "./pages/Meditation/Viewmeditation/Viewmwditation";
import Addmeditation from "./pages/Meditation/Addmeditation/Addmeditation";
import Editmeditation from "./pages/Meditation/Editmeditation/Editmeditation";
import Userlist from "./pages/User/Userlist/Userlist";
import Viewuser from "./pages/User/Viewuser/Viewuser";
import Voiceofthedaylist from "./pages/Voice-of-the-day/Voice_of_the_day_list/Voice_of_the_day_list";
import Addvoiceoftheday from "./pages/Voice-of-the-day/Add-voice-of-the-day/Add-voice-of-the-day";
import Viewvoiceoftheday from "./pages/Voice-of-the-day/View-voice-of-the-day/View-voice-of-the-day";
import Updatevoiceoftheday from "./pages/Voice-of-the-day/Update-voice-of-the-day/Update-voice-of-the-day";
import Dynamiccontents from "./pages/Dynamic-content/Dynamic-contents-list/Dynamiccontentslist";
import Doctorlist from "./pages/Doctor/Doctorlist/Doctorlist";
import Adddoctor from "./pages/Doctor/Doctoradd/Adddoctor";
import Viewdoctor from "./pages/Doctor/DoctorView/Viewdoctor";
import Happylist from "./pages/Happy/Happylist/Happylist";
import Appointmentlist from "./pages/Appointment/Appointmentlist/Appointmentlist";
import Availabletimelist from "./pages/Available-Time/Available-time-list/Availabletimelist";
import Addavailabletime from "./pages/Available-Time/Add-available-time/Addavailabletime";
import Profiledetail from "./pages/Profile/Profiledetail";
import Appointmentlistview from "./pages/Appointment/Appiontmentlistview/Appointmentlistview";
import ChangePassword from "./pages/Change-Password/Change-password";
import Addcontent from "./pages/Dynamic-content/Addcontent/Addcontent";
import ViewHappy from "./pages/Happy/HappyView/Viewhappy";
function App() {
  const token = gettoken();
  const usertype = gettypeofuser();

  return (
    <div className="app">
      <Router>

      <Sidebar>
        <Routes>
         <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={token ? <Dashboard /> : <Login />} />
          <Route path="/profiledetail" element={token ? <Profiledetail /> : <Login />} />

          {/* user pages */}
          <Route path="/change-password" element={token ? usertype === "DOC login" ? <> not found</> : <ChangePassword/> : <Login />} />
          <Route path="/userlist" element={token ? usertype === "DOC login" ? <> not found</> : <Userlist /> : <Login />} />
          <Route path="/view-user" element={token ? usertype === "DOC login" ? <> not found</> : <Viewuser /> : <Login />} />
          {/* doctor pages */}
          <Route path="/doctorlist" element={token ? usertype === "DOC login" ? <> not found</> : <Doctorlist /> : <Login />} />
          <Route path="/add-doctor" element={token ? usertype === "DOC login" ? <> not found</> : <Adddoctor /> : <Login />} />
          <Route path="/view-doctor" element={token ? usertype === "DOC login" ? <> not found</> : <Viewdoctor /> : <Login />} />
          {/* Doctor Available Time pages */}
          <Route path="/available-time-list" element={token ? <Availabletimelist /> : <Login />} />
          <Route path="/add-available-time" element={token ? <Addavailabletime /> : <Login />} />
          {/* Appointment pages */}
          <Route path="/appointmentlistview" element={token ? <Appointmentlistview/> : <Login />} />
          <Route path="/appointment-list" element={token ? <Appointmentlist /> : <Login />} />
          {/* meditation pages */}
          <Route path="/meditationlist" element={token ? usertype === "DOC login" ? <> not found</> : <Meditationlist /> : <Login />} />
          <Route path="/view-meditation" element={token ? usertype === "DOC login" ? <> not found</> : <Viewmwditation /> : <Login />} />
          <Route path="/add-meditation" element={token ? usertype === "DOC login" ? <> not found</> : <Addmeditation /> : <Login />} />
          <Route path="/edit-meditation" element={token ? usertype === "DOC login" ? <> not found</> : <Editmeditation /> : <Login />} />
          {/* voice of the day pages */}
          <Route path="/voice-of-the-day-list" element={token ? usertype === "DOC login" ? <> not found</> : <Voiceofthedaylist /> : <Login />} />
          <Route path="/add-voice-of-the-day" element={token ? usertype === "DOC login" ? <> not found</> : <Addvoiceoftheday /> : <Login />} />
          <Route path="/view-voice-of-the-day" element={token ? usertype === "DOC login" ? <> not found</> : <Viewvoiceoftheday /> : <Login />} />
          <Route path="/update-voice-of-the-day" element={token ? usertype === "DOC login" ? <> not found</> : <Updatevoiceoftheday /> : <Login />} />
          {/* dynamic contents pages */}
          <Route path="/dynamic-contents-list" element={token ? usertype === "DOC login" ? <> not found</> : <Dynamiccontents /> : <Login />} /> 
          <Route path="/add-dynamic-contents" element={token ? usertype === "DOC login" ? <> not found</> : <Addcontent /> : <Login />} /> 
          {/* happy moment pages */}
          <Route path="/happy" element={token ? usertype === "DOC login" ? <> not found</> : <Happylist /> : <Login />} /> 
          <Route path="/view-happy" element={token ? usertype === "DOC login" ? <> not found</> : <ViewHappy /> : <Login />} /> 
          <Route path="*" element={<> not found</>} />
        </Routes>
      </Sidebar>
  
    </Router>
    </div>
  );
}

export default App;
