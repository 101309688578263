import React, { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import { useGetConcentratelistQuery, usePostConcentrateMutation, usePutConcentrateMutation } from "../../../store/api/concentrate-api";


const Popboxs = ({toggleSidebar,sidebarVisible,refetchmeditation = null,}) => {
  const [newconcentrates, setnewconcentrates] = useState("");
  const [editconcentrates, seteditconcentrates] = useState("");
  const [editconcentratesid, seteditconcentratesid] = useState(0);
  const [newconcentrateserror, setnewconcentrateserror] = useState("");
  const [newconcentratessuccess, setnewconcentratessuccess] = useState("");
  const closepop = useRef(null);
  const closepop2 = useRef(null);
  const {data: concentratelist,isLoading: concentrateloader,refetch } = useGetConcentratelistQuery();
  const [createconcentrates] = usePostConcentrateMutation();
  const [editconcentratesapi] = usePutConcentrateMutation(); 


  // add concentrates function 
  async function submitform() {
    try {
      const response = await createconcentrates({
        name: newconcentrates,
      });
      setnewconcentrateserror("");
      setnewconcentratessuccess("Added Successfully");
      if (response.error) {
        setnewconcentrateserror(response.error.data.name[0]);
        setnewconcentratessuccess("");
      } else {
        refetch();
        if (refetchmeditation != null) {
          refetchmeditation();
        }
        setTimeout(() => {
          setnewconcentratessuccess("");
          setnewconcentrates("");
          if (closepop.current) {
            closepop.current.click();
          }
        }, 3000);
      }
    } catch (error) {
      console.log("create Concentrate error", error);
    }
  }


// edit concentrates function 
  async function submitformedit() {
    try {
      const response = await editconcentratesapi({
        data: { name: editconcentrates },
        id: editconcentratesid,
      });
      setnewconcentrateserror("");
      setnewconcentratessuccess("Updated Successfully");
      if (response.error) {
        setnewconcentrateserror(response.error.data.name[0]);
        setnewconcentratessuccess("");
      } else {
        refetch();
        if (refetchmeditation != null) {
          refetchmeditation();
        }
        setTimeout(() => {
          setnewconcentratessuccess("");
          seteditconcentrates("");
          if (closepop2.current) {
            closepop2.current.click();
          }
        }, 3000);
      }
    } catch (error) {
      console.log("create Concentrate error", error);
    }
  }


  return (
    <>
      {/* Sidebar */}
      <div
        className={`sidebar ${sidebarVisible ? "visible" : ""}`}
        style={sidebarVisible ? sidebarStylesvisable : sidebarStyles}
      >
        <button onClick={toggleSidebar} style={closeBtnStyles}>
          &times;
        </button>
        <div style={{ padding: "20px 18px" }}>
          <p
            className="customfont teenheadertitle"
            style={{
              position: "relative",
              top: "-2px",
              width: "fit-content",
            }}
          >
            Concentrate List
          </p>

          <div className="d-flex justify-content-end">
            <button
              className="btn text-white customcolor2"
              style={{ fontSize: "14px" }}
            >
              <NavLink
                data-bs-toggle="modal"
                data-bs-target="#exampleModal1400"
                style={{ textDecoration: "none", color: "white" }}
              >
                {" "}
                + Add New
              </NavLink>
            </button>
          </div>
          {concentrateloader == true ? (
            ""
          ) : (
            <ul className="teenlidesign p-0 mt-2">
              {concentratelist.concentrates.map((item,index) => (
                <li key={index}>
                  <span>{item.name}</span>{" "}
                  <span
                    data-bs-toggle="modal"
                    data-bs-target="#editconcentrates"
                    onClick={() => {
                      seteditconcentrates(item.name);
                      seteditconcentratesid(item.id);
                    }}
                  >
                    <BiEdit fontSize={14} color="#00A192" />
                  </span>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      {/* Overlay to close sidebar when clicking outside */}
      {sidebarVisible && (
        <div style={overlayStyles} onClick={toggleSidebar}></div>
      )}

      {/* add concentrates pop  */}
      <div
        className="modal fade"
        id="exampleModal1400"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
            <div className="modal-header mod-line d-none"></div>
            <div className="modal-body">
              <div className="row gy-3 mt-2">
                <h1 className="ccedit-h" style={{ fontSize: "21px" }}>
                  Add Concentrate
                </h1>
                <p className="ccedit-p">
                  <input
                    type="text"
                    value={newconcentrates}
                    onChange={(e) => {
                      setnewconcentrates(e.target.value);
                    }}
                    className="form-control teenfontsize2 "
                    placeholder="Enter Concentrate"
                    style={{ minHeight: "39px" }}
                  />
                </p>
              </div>
            </div>
            <div className="modal-footer mod-line m-auto">
              <button
                type="button"
                className="btn"
                data-bs-dismiss="modal"
                style={{
                  background: "#F2F2F2",
                  color: "#7C7878",
                  fontSize: "14px",
                }}
                ref={closepop}
              >
                Cancel
              </button>

              {newconcentrates != "" ? (
                <button
                  className="btn closebtn text-white"
                  type="button"
                  onClick={() => {
                    submitform();
                  }}
                  style={{ fontSize: "14px" }}
                >
                  Save
                </button>
              ) : (
                <button
                  className="btn closebtn text-white"
                  type="button"
                  disabled
                  style={{ fontSize: "14px" }}
                >
                  Save
                </button>
              )}
            </div>
            <div className="modal-footer mod-line m-auto">
              <span style={{ color: "green" }}>
                {newconcentratessuccess != "" ? newconcentratessuccess : ""}
              </span>
              <span style={{ color: "red" }}>
                {newconcentrateserror != "" ? newconcentrateserror : ""}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* edit concentrates pop  */}
      <div
        className="modal fade"
        id="editconcentrates"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
            <div className="modal-header mod-line d-none"></div>
            <div className="modal-body">
              <div className="row gy-3 mt-2">
                <h1 className="ccedit-h" style={{ fontSize: "21px" }}>
                  Edit Concentrate
                </h1>
                <p className="ccedit-p">
                  <input
                    type="text"
                    value={editconcentrates}
                    onChange={(e) => {
                      seteditconcentrates(e.target.value);
                    }}
                    className="form-control teenfontsize2 "
                    placeholder="Enter Concentrate"
                    style={{ minHeight: "39px" }}
                  />
                </p>
              </div>
            </div>
            <div className="modal-footer mod-line m-auto">
              <button
                type="button"
                className="btn"
                data-bs-dismiss="modal"
                style={{
                  background: "#F2F2F2",
                  color: "#7C7878",
                  fontSize: "14px",
                }}
                ref={closepop2}
              >
                Cancel
              </button>

              {editconcentrates != "" ? (
                <button
                  className="btn closebtn text-white"
                  type="button"
                  onClick={() => {
                    submitformedit();
                  }}
                  style={{ fontSize: "14px" }}
                >
                  Update
                </button>
              ) : (
                <button
                  className="btn closebtn text-white"
                  type="button"
                  disabled
                  style={{ fontSize: "14px" }}
                >
                  Update
                </button>
              )}
            </div>
            <div className="modal-footer mod-line m-auto">
              <span style={{ color: "green" }}>
                {newconcentratessuccess != "" ? newconcentratessuccess : ""}
              </span>
              <span style={{ color: "red" }}>
                {newconcentrateserror != "" ? newconcentrateserror : ""}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// Sidebar styles
const sidebarStyles = {
  position: "fixed",
  top: "0",
  right: "-300px",
  width: "300px",
  height: "100%",
  backgroundColor: "#f8f9fa",
  zIndex: "1000",
  transition: "right 0.3s ease",
};

const sidebarStylesvisable = {
  position: "fixed",
  top: "0",
  right: "0px",
  width: "300px",
  height: "100%",
  backgroundColor: "#f8f9fa",
  zIndex: "1000",
  transition: "right 0.3s ease",
};

const closeBtnStyles = {
  position: "absolute",
  top: "10px",
  right: "18px",
  fontSize: "24px",
  background: "none",
  border: "none",
  cursor: "pointer",
};

const overlayStyles = {
  position: "fixed",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0,0,0,0.5)",
  zIndex: "999",
};

export default Popboxs;
