import React, { useEffect } from "react";
import img2 from "../../assets/kyc.png";
import img3 from "../../assets/lo.png";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Loadercomp from "../../components/Loadercomp";
import { usePostLoginMutation } from "../../store/api/adminapi";
import { currentuserid, gettoken, gettypeofuser, refreshtokenstore, tokenstore, typeofuserstore } from "../../Localstorage/Store";

const Login = () => {
  const [emailfield, setemailfield] = useState();
  const [passwordfield, setpasswordfield] = useState();
  const [loginresponse, setloginresponse] = useState();
  const [loading, setloading] = useState(false);
  const nvg = useNavigate();
  const [loginapi] = usePostLoginMutation();
  const usertype = gettypeofuser()

  async function submitform(e) {
    e.preventDefault();
    setloading(true);
    const jsondata = {
      email: emailfield,
      password: passwordfield,
    };
    try {
      const response = await loginapi(jsondata);
      if (response.error) {
        setloginresponse("Incorrect Email or password");
      } else {
        console.log("api respose",response);
        tokenstore(response.data.access)
        refreshtokenstore(response.data.refresh)
        typeofuserstore(response.data.msg)
        currentuserid(response.data.id)
        if(response.data.msg == "DOC login"){
          nvg("/appointment-list");
        }else{
          nvg("/dashboard");
        }
      }
      setloading(false);
    } catch (error) {
      setloading(false);
    }
  }

  useEffect(()=>{
    const token = gettoken();
    if(token){

     if(usertype == "DOC login"){
      window.location.href="/appointment-list";

     }else{
       window.location.href="/dashboard";
      }
    }
  },[])

  return (
    <div className="container loginpage d-flex justify-content-between align-items-center" style={{overflow:'auto'}}>
      <div className="row">
        <div className="col-lg-6 lp-left px-lg-1 px-md-1">
          <form onSubmit={submitform}>
            <div className="container px-lg-2 px-md-1">
              <div className="row mb-4">
                <div className="col-lg-7 col-8 offset-lg-0">
                  <img
                    src={img3}
                    style={{ mixBlendMode: "darken" }}
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-10 col-md-10 col-12 offset-lg-0 offset-0">
                  <p className="welcomeback">Welcome Back</p>
                  <h3 className="welcomeback2 mb-3">Login to your account</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-10 col-md-10 col-12 offset-lg-0">
                  <input
                    type="text"
                    className="form-control customheight mb-3"
                    value={emailfield}
                    onChange={(e) => {
                      setemailfield(e.target.value);
                    }}
                    required
                    placeholder="Enter Your Email"
                  />
                </div>
                <div className="col-lg-10 col-md-10 col-12 offset-lg-0">
                  <input
                    type="password"
                    className="form-control customheight mb-3"
                    value={passwordfield}
                    onChange={(e) => {
                      setpasswordfield(e.target.value);
                    }}
                    required
                    placeholder="Password"
                  />
                </div>
                <div
                  className="col-12 d-flex justify-content-center"
                  style={{
                    gap: "4px",
                    position: "absolute",
                    width: "91%",
                    zIndex: "3",
                  }}
                >
                  <div className="px-2">
                    {loading === true ? <Loadercomp size={100} /> : ""}
                  </div>
                </div>
                <div className="col-lg-10 col-md-10 col-12 offset-lg-0 d-flex justify-content-between">
                  <div className="form-check ">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label "
                      htmlFor="flexCheckDefault"
                    >
                      Remember me
                    </label>
                  </div>
                  <div>
                    <NavLink
                      to="/resetpassword"
                      style={{ textDecoration: "none" }}
                    >
                      <p className="customdesgi">Forgot password ?</p>
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-10 col-md-10 col-12 offset-lg-0">
                  <button
                    className="btn text-white mt-4 customcolor2 customheight"
                    type="submit"
                    style={{ width: "100%", fontWeight: "bold" }}
                  >
                    Login
                  </button>
                </div>
                <div className="col-lg-10 col-md-10 col-12 offset-lg-0 mt-2 ">
                  {loginresponse ? (
                    <div className="alert alert-danger" role="alert">
                      {loginresponse}
                    </div>
                  ) : ( "")}
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className=" col-6 lp-right isnone">
          <div className="overlapimg">
            <img src={img2} alt="mnbvc" style={{ width: "100%" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
