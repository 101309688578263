
const Convertdate = (item) => {
  const toDate = new Date(item);
  const readableDate = toDate.toLocaleString("en-US", {
    timeZone: "UTC",
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit", 
    minute: "2-digit",
    hour12: true, 
  });
  return readableDate
};

export default Convertdate;
