import React, { useEffect, useRef, useState } from "react";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useLocation, useNavigate } from "react-router-dom";
import Loadercomp from "../../../components/Loadercomp";
import beat from "../../../assets/bit.png";
import { FaPause, FaPlay } from "react-icons/fa";
import Breadcup from "../../../components/Breadcup";
import { useGetDoctordetailQuery } from "../../../store/api/doctorapi";

const Viewdoctor = () => {
  const location = useLocation();
  const nvg = useNavigate();
  const [data, setdata] = useState({});
  const [srtloader, setsrtloader] = useState(true);

  const { data:doc_details, error, isLoading } = useGetDoctordetailQuery({id:location.state.data.id});
console.log(error,"twt");

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  return (
    <div style={{ width: "100%", minHeight: "100vh" }}>
      <div className="dashboardcontent px-2">
        <Breadcup name={"Doctor"} secondname={"View Doctor"} />
        <div
          className="container-fuild px-2 mb-1"
          style={{ overflow: "hidden" }}
        >
          <div className="row bg-white ">
            <div
              className="col-lg-12 d-flex justify-content-between py-2"
              style={{ color: "#0C5398" }}
            >
              <p
                className="m-0 customfont teenheadertitle py-2"
                style={{ color: "#62153C" }}
              >
                Doctor View
              </p>
              <div className="addnew d-block mb-2">
                <button
                  className="btn "
                  type="button"
                  onClick={() => {
                    nvg("/doctorlist");
                  }}
                  style={{
                    outline: "none",
                    background: "white",
                    color: "#00A192",
                    fontSize: "19px",
                    fontWeight: "550",
                  }}
                >
                  {" "}
                  X{" "}
                </button>
              </div>
            </div>
          </div>
        </div>

        {isLoading == true ? (
          <div className="container-fuild bg-white">
            {" "}
            <div
              className="col-12 d-flex justify-content-center"
              style={{ gap: "4px", position: "absolute", width: "70%" }}
            >
              <div className="loading d-flex justify-content-center">
                {" "}
                <Loadercomp size={100} />
              </div>
            </div>
          </div>
        ) : (
          <div className="container-fuild pb-4 pt-3 px-2 bg-white">
            <div
              className="row bg-white round"
              style={{
                border: "1px solid #E0E0E0",
                margin: "10px 0px",
                borderRadius: "3px",
              }}
            >
              <div className="col-lg-6 px-4 pt-3">
                <div className="row">
                  <div className="col-md-3 col-4 d-flex align-items-center">
                    <label htmlFor="" className="form-label teenfontsize m-0">
                      Full Name
                    </label>
                  </div>
                  <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                    <input
                      type="text"
                      className="form-control teenfontsize2"
                      value={`${doc_details.accounts.first_name} ${doc_details.accounts.last_name}`}
                      readOnly
                      placeholder="Enter Title"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 px-4 pt-3">
                <div className="row">
                  <div className="col-md-3 col-4 d-flex align-items-center">
                    <label
                      htmlFor=""
                      className="form-label teenfontsize customw m-0"
                    >
                      Designation
                    </label>
                  </div>
                  <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                    <input
                      type="text"
                      className="form-control teenfontsize2"
                      value={doc_details.accounts.designation}
                      readOnly
                      placeholder="Enter Designation"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 px-4 pt-3">
                <div className="row">
                  <div className="col-md-3 col-4 d-flex align-items-center">
                    <label
                      htmlFor=""
                      className="form-label teenfontsize customw m-0"
                    >
                      Email ID
                    </label>
                  </div>
                  <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                    <input
                      type="text"
                      className="form-control teenfontsize2"
                      value={doc_details.accounts.email}
                      readOnly
                      placeholder="Enter Email"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 px-4 pt-3">
                <div className="row">
                  <div className="col-md-3 col-4 d-flex align-items-center">
                    <label
                      htmlFor=""
                      className="form-label teenfontsize customw m-0"
                    >
                      Phone No.
                    </label>
                  </div>
                  <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                    <input
                      type="text"
                      className="form-control teenfontsize2"
                      value={doc_details.accounts.phone_number}
                      readOnly
                      placeholder="Enter Phone No."
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 px-4 pt-3">
                <div className="row">
                  <div className="col-md-3 col-4 d-flex align-items-center">
                    <label
                      htmlFor=""
                      className="form-label teenfontsize customw m-0"
                    >
                      Price 
                    </label>
                  </div>
                  <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                    <input
                      type="text"
                      className="form-control teenfontsize2"
                      value={doc_details.amount}
                      readOnly
                      placeholder="Enter Amount"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 px-4 pt-3">
                <div className="row">
                  <div className="col-md-3 col-4 d-flex align-items-center">
                    <label
                      htmlFor=""
                      className="form-label teenfontsize customw m-0"
                    >
                      Doctor Type 
                    </label>
                  </div>
                  <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                    <input
                      type="text"
                      className="form-control teenfontsize2"
                      value={doc_details.doctor_type}
                      readOnly
                      placeholder="Enter Amount"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 px-4 pt-3">
                <div className="row">
                  <div className="col-md-3 col-4 d-flex align-items-center">
                    <label
                      htmlFor=""
                      className="form-label teenfontsize customw m-0"
                    >
                      Experience
                    </label>
                  </div>
                  <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                    <input
                      type="text"
                      className="form-control teenfontsize2"
                      value={doc_details.experience}
                      readOnly
                      placeholder="Enter Experience"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 px-4 pt-3">
                <div className="row">
                  <div className="col-md-3 col-4 d-flex align-items-center">
                    <label htmlFor="" className="form-label teenfontsize m-0">
                      Created At{" "}
                    </label>
                  </div>
                  <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                    <input
                      type="datetime-local"
                      className="form-control teenfontsize2"
                      value={new Date(doc_details.created_at)
                        .toISOString()
                        .slice(0, 16)}
                      readOnly
                      placeholder="Enter Title"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 px-4 pt-3 pb-3">
                <div className="row">
                  <div className="col-md-3 col-4 d-flex align-items-center">
                    <label htmlFor="" className="form-label teenfontsize m-0">
                      About Doctor
                    </label>
                  </div>
                  <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                    <textarea
                      className="form-control "
                      value={doc_details.about}
                      readOnly
                      placeholder="Enter Title"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 px-4 pt-3">
                <div className="row align-items-start">
                  <div className="col-md-3 col-4 d-flex align-items-center">
                    <label htmlFor="" className="form-label teenfontsize m-0">
                    Doctor Image
                    </label>
                  </div>
                  <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-center">
                   <img src={doc_details.profile_pic} style={{width:'261px'}} alt="404" />
                  </div>
                </div>
              </div>


             


            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Viewdoctor;
