import { useState } from "react";
import Table from "../../../components/List/Table";
import { useGetAppointmentlistQuery } from "../../../store/api/appointment-api";
import Pagination from "../../../components/List/Pagination";
import { gettypeofuser } from "../../../Localstorage/Store";
import Sort from "../../../components/List/Sort";
import Loadercomp from "../../../components/Loadercomp";
import Convertdate from "../../../components/List/Convertdate";
import Filter from "../../../components/List/Filter";

const Appointmentlist = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filterValues, setFilterValues] = useState({});
  const usertype = gettypeofuser();

  const action = [{ icon: "view", url: "/appointmentlistview" }];
  const headfields = {
    0: { label: "S.NO", key: "serial_no", increameant: true },
    2: { label: "Nickname", key: "nickname" },
    3: { label: "From Time", key: "from_time", formatter: Convertdate },
    4: { label: "To Time", key: "to_time", formatter: Convertdate },
    5: { label: "Status", key: "payment_status" },
    7: { label: "Created Date", key: "created_date", formatter: Convertdate },
  };
  const filters = {
    0: { key: "nickname", type: "text", placeholder: "Nickname" },
    2: {
      key: "payment_status",
      type: "select",
      placeholder: "Payment Status",
      options: [
        { value: "SUCCESS", label: "SUCCESS" },
        { value: "PENDING", label: "PENDING" },
      ],
    },
    3: { key: "created_date", type: "date", placeholder: "Created At" },
    4: { key: "from_date", type: "datetime", placeholder: "From Time" },
    5: { key: "to_date", type: "datetime", placeholder: "To Time" },
  };
  if (usertype === "Admin Login") {
    headfields[1] = { label: "Doctor Name", key: "doctor_name" };
    headfields[6] = { label: "Amount", key: "price" };
    filters[1] = {
      key: "doctorname",
      type: "text",
      placeholder: "Doctor Name",
    };
  }
  const filterComponents = Object.values(filters).map((filter) => ({
    ...filter,
    value: filterValues[filter.key] || "",
  }));
  const handleFilterChange = (key, value) => {
    setFilterValues((prev) => ({ ...prev, [key]: value }));
  };
  const queryParameters = Object.values(filters).reduce((acc, filter) => {
    acc[filter.key] = filterValues[filter.key] || "";
    return acc;
  }, {});
  const { data: tabledata, isLoading } = useGetAppointmentlistQuery({
    page: currentPage,
    pageSize,
    ...queryParameters,
  });

  return (
    <div className="minheight" style={{ width: "100%", minHeight: "100vh" }}>
      <div className="dashboardcontent px-2">
        <div className="container-fluid px-2">
          <div className="row bg-white py-3 rounded-top">
            <div className="col-lg-3 d-flex justify-content-between">
              <p className="m-0 customfont teenheadertitle">
                Appointment List
              </p>
            </div>
          </div>

          <div
            className="row bg-white pb-4 rounded-bottom table-responsive"
            style={{ paddingBottom: "7rem" }}
          >
            {isLoading === true ? (
              <div className="d-flex justify-content-center">
                <Loadercomp />
              </div>
            ) : (
              <>
                <Filter
                  filters={filterComponents}
                  onFilterChange={handleFilterChange}
                />
                <Table
                  headfields={headfields}
                  action={action}
                  tabledata={tabledata.results}
                />
                <div className="d-flex" style={{ justifyContent: "space-between" }} >
                  <Sort
                    setPageSize={setPageSize}
                    setCurrentPage={setCurrentPage}
                    pageSize={pageSize}
                  />
                  <Pagination
                    setCurrentPage={setCurrentPage}
                    data={tabledata}
                    pageSize={pageSize}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Appointmentlist;
