import React, { useEffect, useState } from "react";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useLocation, useNavigate } from "react-router-dom";
import Loadercomp from "../../../components/Loadercomp";
import Breadcup from "../../../components/Breadcup";

const ViewHappy = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    if (location.state?.data) {
      setData(location.state.data);
      setLoading(false);
    } else {
      navigate("/error");
    }
  }, [location, navigate]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
        <Loadercomp size={100} />
      </div>
    );
  }

  return (
    <div style={{ width: "100%", minHeight: "100vh" }}>
      <div className="dashboardcontent px-2">
        <Breadcup name="Happy" secondname="View Happy" />

        <div className="container-fluid px-2 mb-1">
          <div className="row bg-white">
            <div className="col-lg-12 d-flex justify-content-between py-2" style={{ color: "#0C5398" }}>
              <h4 className="m-0 customfont teenheadertitle py-2" style={{ color: "#62153C" }}>
                View Happy Details
              </h4>
              <button
                className="btn"
                onClick={() => navigate("/happy")}
                style={{ background: "white", color: "#00A192", fontSize: "16px", fontWeight: "550" }}
              >
                X
              </button>
            </div>
          </div>
        </div>

        <div className="container-fluid pb-4 pt-3 px-2 bg-white">
          <div
            className="row"
            style={{ border: "1px solid #E0E0E0", borderRadius: "3px", margin: "10px 0" }}
          >
            {[
              { label: "Title", key: "title" },
              { label: "Total Likes", key: "total_likes" },
              { label: "Publish By", key: "publish_by" },
              { label: "Created Date & Time", key: "create_date" },
            ].map((field, idx) => (
              <div key={idx} className="col-lg-6 px-4 pt-3">
                <div className="row">
                  <label
                    htmlFor={field.key}
                    className="col-md-3 col-4 form-label"
                    style={{ fontSize: "12px" }}
                  >
                    {field.label}:
                  </label>
                  <div className="col-lg-9 col-md-8 col-8">
                    <input
                      id={field.key}
                      name={field.key}
                      type="text"
                      value={field.key === "create_date" && data[field.key] ? new Date(data[field.key]).toLocaleString() : data[field.key] || ""}
                      className="form-control"
                      readOnly
                      style={{ fontSize: "12px" }}
                    />
                  </div>
                </div>
              </div>
            ))}

            <div className="col-lg-6 px-4 pt-3">
              <div className="row">
                <label htmlFor="profile_pic" className="col-md-3 col-4 form-label" style={{ fontSize: "12px" }}>
                  Profile Image:
                </label>
                <div className="col-lg-9 col-md-8 col-8">
                  {data.file ? (
                    <img
                      src={data.file}
                      alt="Profile"
                      className="img-fluid rounded"
                      style={{ width: "100px", height: "100px", objectFit: "cover" }}
                    />
                  ) : (
                    <div
                      className="d-flex align-items-center justify-content-center border bg-light"
                      style={{
                        width: "100px",
                        height: "100px",
                      }}
                    >
                      <span className="text-muted">No Image</span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-lg-6 px-4 pt-3">
              <div className="row">
                <label htmlFor="about" className="col-md-3 col-4 form-label" style={{ fontSize: "12px" }}>
                  About:
                </label>
                <div className="col-lg-9 col-md-8 col-8">
                  <textarea
                    id="about"
                    name="about"
                    value={data.about || ""}
                    className="form-control"
                    readOnly
                    rows="3"
                    style={{ fontSize: "12px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewHappy;

