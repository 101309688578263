const Breadcup = ({name, secondname}) => {
  if(secondname){
    return (
      <div style={{width:'100%',padding:'14px 0px',color:'#AAABAF',fontSize:'16px'}}>
       {name} &nbsp;  &gt;  &nbsp; {secondname}
      </div>
    )
  }else{
    return (
      <div style={{width:'100%',padding:'14px 0px',color:'#AAABAF',fontSize:'16px'}}>
       Dashboard &nbsp;  &gt;  &nbsp; {name}
      </div>
    )
  }
}

export default Breadcup