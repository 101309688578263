import { BsThreeDotsVertical } from "react-icons/bs";
import Popup from "./Popup";

const Countbox = ({
  count,
  title,
  img1,
  filterfields,
  defaultvalue,
  onFilterChange,
}) => {
  return (
    <div className="col-lg-4 mb-2 col-md-6 col-12 dbox">
      <div className="row bg-white py-3 d-flex align-items-center rounded py-2">
        <div className="col-3"> 
          <img src={img1} alt="" style={{width:'65px'}} />
        </div>
        <div className="col-5">
          <p className="dbold">{count}</p>
          <p className="pbold">{title.label}</p>
        </div>
        <div className="col-4 d-flex justify-content-end">
          <BsThreeDotsVertical
            data-bs-toggle="modal"
            data-bs-target={`#model12345${title.key}`}
          />
        </div>
      </div>

      <Popup
        title={{
          label: `Add ${title.label.split(" ")[1]} Filter`,
          key: title.key,
        }}
        filters={filterfields}
        onFilterChange={onFilterChange}
        defaultvalue={defaultvalue}
      />
      {/* <div
        className="modal fade"
        id={`exampleModal1400${title.key}`}
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
            <div className="modal-header mod-line d-none"></div>
            <div className="modal-body">
              <div className="row gy-3 mt-2">
                <h1 className="ccedit-h" style={{ fontSize: "21px" }}>
                  Add {title.label.split(" ")[1]} Filters
                </h1>
                <p className="ccedit-p" style={{ marginBottom: "0px" }}>
                  <input
                    type="date"
                    name="startDate"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    className="form-control custselect"
                    style={{ fontSize: "14px" }}
                    placeholder="Start Date"
                  />
                </p>
                <p className="ccedit-p">
                  <input
                    type="date"
                    name="endDate"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    className="form-control custselect"
                    style={{ fontSize: "14px" }}
                    placeholder="End Date"
                  />
                </p>
              </div>
            </div>
            <div className="modal-footer mod-line m-auto">
              <button
                type="button"
                className="btn"
                data-bs-dismiss="modal"
                style={{
                  background: "#F2F2F2",
                  color: "#7C7878",
                  fontSize: "14px",
                }}
                ref={closepop}
              >
                Cancel
              </button>

              <button
                className="btn closebtn text-white"
                type="button"
                onClick={() => {
                  submitform();
                }}
                style={{ fontSize: "14px" }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Countbox;
