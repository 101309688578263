import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('token');
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && (result.error.status === 401 || result.error.status === 403)) {

    localStorage.removeItem('token');

  }

  return result;
};


export const availabletimeApi = createApi({
  reducerPath: 'availabletimeApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAvatarslist: builder.query({
      query: () => ({
        url: `/user/avatars/`,
        method:'GET',
      })
    }),
    postAvailableTime: builder.mutation({
      query: (data) => ({
        url: `/doc-module/add-availabletime`,
        method:'POST',
        body:data
      })
    }),
    putAvatar: builder.mutation({
      query: ({data,id}) => ({
        url: `/user/avatars/${id}/`,
        method:'PUT',
        body:data
      })
    }),
  }),
})

export const { useGetAvatarslistQuery,usePostAvailableTimeMutation,usePutAvatarMutation } = availabletimeApi