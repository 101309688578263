import { NavLink, useNavigate } from "react-router-dom";
import Sort from "../../../components/List/Sort";
import Loadercomp from "../../../components/Loadercomp";
import Pagination from "../../../components/List/Pagination";
import Table from "../../../components/List/Table";
import { useState } from "react";
import Convertdate from "../../../components/List/Convertdate";
import Filter from "../../../components/List/Filter";
import { useGetVoiceofthedaylistQuery } from "../../../store/api/voiceofthedayapi";

const Voiceofthedaylist = () => {
  const nvg = useNavigate();
  const [filterValues, setFilterValues] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);


  const action = [{ icon: "view", url: "/view-voice-of-the-day" }];

  const headfields = {
    0: { label: "S.NO", key: "serial_no", increameant: true },
    1: { label: "Title", key: "title" },
    3: { label: "Content", key: "content" },
    4: { label: "Created At", key: "create_date", formatter: Convertdate },
  };

  const filters = {
    0: { key: "title", type: "text", placeholder: "Title" },
    1: { key: "content", type: "text", placeholder: "Content" },
    2: { key: "create_date", type: "datetime", placeholder: "Created At" },
  };

  const filterComponents = Object.values(filters).map((filter) => ({
    ...filter,
    value: filterValues[filter.key] || "",
  }));
  const handleFilterChange = (key, value) => {
    setFilterValues((prev) => ({ ...prev, [key]: value }));
  };

  const queryParameters = Object.values(filters).reduce((acc, filter) => {
    acc[filter.key] = filterValues[filter.key] || "";
    return acc;
  }, {});

  const { data: tabledata, isLoading } = useGetVoiceofthedaylistQuery({
    page: currentPage,
    page_size: pageSize,
    ...queryParameters,
  });
  return (
    <div className="minheight" style={{ width: "100%", minHeight: "100vh" }}>
      <div className="dashboardcontent px-2">
        <div className="container-fluid px-2">
          <div className="row bg-white py-3 rounded-top">
            <div className="col-lg-3 d-flex justify-content-between">
              <p className="m-0 customfont teenheadertitle">
                Voice of The Day List
              </p>
            </div>
            <div
              className="col-lg-9 d-flex justify-content-end align-items-center"
              style={{ gap: "7px" }}
            >
              <div
                className="addnew d-lg-flex d-none"
                style={{ flexDirection: "column", gap: "4px" }}
              >
                <button
                  className="btn text-white customcolor2"
                  style={{ fontSize: "13px" }}
                  onClick={() => {
                    nvg("/add-voice-of-the-day");
                  }}
                >
                  <NavLink
                    to="/add-voice-of-the-day"
                    style={{ textDecoration: "none", color: "white" }}
                  > + Add New </NavLink>
                </button>
              </div>
            </div>
          </div>

          {isLoading === true ? (
             <div className="loading d-flex justify-content-center">
             <Loadercomp />
           </div>
          ) : (
            <div
              className="row bg-white pb-4 rounded-bottom table-responsive"
              style={{ paddingBottom: "7rem" }}
            >
              <Filter
                filters={filterComponents}
                onFilterChange={handleFilterChange}
              />
              <Table
                headfields={headfields}
                action={action}
                tabledata={tabledata.results}
              />
              <div
                className="d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <Sort
                  setPageSize={setPageSize}
                  setCurrentPage={setCurrentPage}
                  pageSize={pageSize}
                />
                <Pagination
                  setCurrentPage={setCurrentPage}
                  data={tabledata}
                  pageSize={pageSize}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Voiceofthedaylist;
