import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Sort from "../../../components/List/Sort";
import Table from "../../../components/List/Table";
import Popboxs from "./Popboxs";
import Loadercomp from "../../../components/Loadercomp";
import Pagination from "../../../components/List/Pagination";
import Convertdate from "../../../components/List/Convertdate";
import { useGetMeditationlistQuery } from "../../../store/api/meditationapi";
import Filter from "../../../components/List/Filter";

const Meditationlist = () => {
  const nvg = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [filterValues, setFilterValues] = useState({});


  const action = [{ icon: "view", url: "/view-meditation" }];
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };
  const headfields = {
    0: { label: "S.NO", key: "serial_no", increameant: true },
    1: { label: "Title", key: "title" },
    3: { label: "Category", key: "category_by_name" },
    4: { label: "Created by", key: "created_by_name" },
    5: { label: "Created At", key: "uploaded_at", formatter: Convertdate },
  };
  const filters = {
    0: { key: "title", type: "text", placeholder: "Title" },
    1: { key: "category", type: "text", placeholder: "Category Name" },
    2: { key: "created_by", type: "text", placeholder: "Created by" },
    3: { key: "uploaded_at", type: "date", placeholder: "Created At" },
  };
  const filterComponents = Object.values(filters).map((filter) => ({
    ...filter,
    value: filterValues[filter.key] || "",
  }));
  const handleFilterChange = (key, value) => {
    setFilterValues((prev) => ({ ...prev, [key]: value }));
  };

  const queryParameters = Object.values(filters).reduce((acc, filter) => {
    acc[filter.key] = filterValues[filter.key] || "";
    return acc;
  }, {});

  const { data: tabledata, isLoading } = useGetMeditationlistQuery({
    page: currentPage,
    page_size: pageSize,
    ...queryParameters,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="minheight" style={{ width: "100%", minHeight: "100vh" }}>
      <div className="dashboardcontent px-2">
        <div className="container-fluid px-2">
          <div className="row bg-white py-3 rounded-top">
            <div className="col-lg-3 d-flex justify-content-between align-items-center">
              <p className="m-0 customfont teenheadertitle">Meditation List</p>
            </div>
            <div
              className="col-lg-9 d-flex justify-content-end align-items-center"
              style={{ gap: "7px" }}
            >
              <div
                className="addnew d-lg-flex d-none"
                style={{ gap: "4px" }}
              >
                <button
                  className="btn text-white customcolor2"
                  style={{ fontSize: "13px" }}
                  onClick={() => {
                    nvg("/add-meditation");
                  }}
                >
                  <NavLink
                    to="/add-meditation"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    {" "}
                    + Add New
                  </NavLink>
                </button>
                <button
                  className="btn text-white customcolor2"
                  style={{ fontSize: "13px" }}
                  onClick={toggleSidebar}
                >
                  + Add Category
                </button>
              </div>
            </div>
          </div>

          {isLoading === true ? (
            <>
              <div className="loading d-flex justify-content-center">
                <Loadercomp />
              </div>
            </>
          ) : (
            <div
              className="row bg-white pb-4 rounded-bottom table-responsive"
              style={{ paddingBottom: "7rem" }}
            >
              <Filter
                filters={filterComponents}
                onFilterChange={handleFilterChange}
              />
              <Table
                headfields={headfields}
                action={action}
                tabledata={tabledata.results}
              />

              <Popboxs
                sidebarVisible={sidebarVisible}
                toggleSidebar={toggleSidebar}
              />

              <div
                className="d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <Sort
                  setPageSize={setPageSize}
                  setCurrentPage={setCurrentPage}
                  pageSize={pageSize}
                />
                <Pagination
                  setCurrentPage={setCurrentPage}
                  data={tabledata}
                  pageSize={pageSize}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Meditationlist;
