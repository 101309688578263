import React, { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

const Chart2 = ({ data,defaultvalue }) => {

  const [chartData, setChartData] = useState([]);
  
    function generateAllDates(year, month) {
      const dates = [];
      const lastDate = new Date(year, month + 1, 0).getDate();
  
      for (let day = 1; day <= lastDate; day++) {
        const createdDate = new Date(year, month, day);
        dates.push(createdDate.toISOString().split("T")[0]);
      }
  
      return dates;
    }
     
    function getModifiedData(apiData, year, month) {
      const allDates = generateAllDates(year, month);
      return allDates.map((date, index) => {
        const dataForDate = apiData.find((item) => item.appointment_date === date);
        return {
          day: index + 1,
          created_date: date,
          user_count: dataForDate ? dataForDate.appointment_count : 0,
        };
      });
    }
  
    useEffect(() => {
      const modifiedData = getModifiedData(data, defaultvalue.year, defaultvalue.month-1);
      setChartData(modifiedData);
    }, [data,defaultvalue]);
  

  return (
    <div
      className="col-lg-6 col-md-12 dbox"
      style={{backgroundColor: "#efefef" }}
    >
      <div className="row rounded bg-white mt-3">
        <div className="col-12 d-flex justify-content-between align-items-center">
          <div
            className="dtext py-2"
            style={{ fontSize: "18px", color: "#62153c" }}
          >
            Appointment
          </div>
           <div>
                      <BsThreeDotsVertical
                        data-bs-toggle="modal"
                        data-bs-target={`#model1234512`}
                      />
                      </div>
        </div>
        <div className="col-12 px-2">
          <div className="underline"></div>
        </div>
        <div className="col-12 py-3">
          <ResponsiveContainer width="100%" aspect={2}>
            <AreaChart
              data={chartData}
              margin={{ top: 10, right: 30, bottom: 5, left: 0 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="day" />
              <YAxis dataKey="user_count" />
              <Tooltip
                contentStyle={{
                  background: "#00A192",
                  border: "1px solid",
                  outline: "none",
                }}
              />
              <Area
                type="monotone"
                dataKey="user_count"
                stroke="#00A192"
                fill="#00A192"
                fillOpacity={0.3}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default Chart2;
