import React, { useState } from "react";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import Loadercomp from "../../../components/Loadercomp";
import Breadcup from "../../../components/Breadcup";
import { usePostDoctorMutation } from "../../../store/api/doctorapi";

const AddDoctor = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState({ type: "", message: "" });
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    doctortype: "",
    amount: "",
    experience: "",
    about: "",
    password: "",
    profile_pic: null,
  });

  const [createDoctor] = usePostDoctorMutation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "mobile" && !/^\d*$/.test(value) ? prevData[name] : value,
    }));
  };

  const handleFileChange = (e) => {
    setFormData((prevData) => ({ ...prevData, profile_pic: e.target.files[0] }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setFeedbackMessage({ type: "", message: "" });

    const formPayload = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      formPayload.append(key === "mobile" ? "phone_number" : key, value);
    });

    try {
      const response = await createDoctor(formPayload);
      const successMessage = response?.data?.message;
      if (successMessage === "Doctor Created.") {
        setFeedbackMessage({ type: "success", message: "Doctor created successfully!" });
        setTimeout(() => {
          setFormData({
            firstname: "",
            lastname: "",
            email: "",
            mobile: "",
            doctortype: "",
            amount: "",
            experience: "",
            about: "",
            password: "",
            profile_pic: null,
          });
          navigate("/doctorlist");
        }, 3000);
      } else {
        setFeedbackMessage({
          type: "error",
          message: response?.error?.data?.message || "Something went wrong.",
        });
      }
    } catch (error) {
      setFeedbackMessage({
        type: "error",
        message: "An unexpected error occurred. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ width: "100%", minHeight: "100vh" }}>
      <div className="dashboardcontent px-2">
        <Breadcup name="User" secondname="Add User" />

        <div className="container-fluid px-2 mb-1">
          <div className="row bg-white">
            <div className="col-lg-12 d-flex justify-content-between py-2" style={{ color: "#0C5398" }}>
              <h4 className="m-0 customfont teenheadertitle py-2" style={{ color: "#62153C" }}>
                Add Doctor
              </h4>
              <button
                className="btn"
                onClick={() => navigate("/doctorlist")}
                style={{ background: "white", color: "#00A192", fontSize: "12px", fontWeight: "550" }}
              >
                X
              </button>
            </div>
          </div>
        </div>

        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Loadercomp size={100} />
          </div>
        ) : (
          <div className="container-fluid pb-4 pt-3 px-2 bg-white">
            <form onSubmit={handleSubmit}>
              <div
                className="row"
                style={{ border: "1px solid #E0E0E0", borderRadius: "3px", margin: "10px 0" }}
              >
                {["firstname", "lastname", "email", "mobile", "doctortype", "amount", "experience", "password"].map(
                  (field, idx) => (
                    <div key={idx} className="col-lg-6 px-4 pt-3">
                      <div className="row">
                        <label
                          htmlFor={field}
                          className="col-md-3 col-4 form-label"
                          style={{ fontSize: "14px",lineHeight:"30px" }}
                        >
                          {field.charAt(0).toUpperCase() + field.slice(1).replace("_", " ")} <span style={{color:'red'}}>*</span>
                        </label>
                        <div className="col-lg-9 col-md-8 col-8">
                          <input
                            id={field}
                            name={field}
                            type={
                              field === "password"
                                ? "password"
                                : field === "mobile" || field === "amount"
                                ? "number"
                                : "text"
                            }
                            value={formData[field]}
                            onChange={handleChange}
                            className="form-control"
                            placeholder={`Enter ${field}`}
                            required
                            style={{ fontSize: "14px" }}
                          />
                        </div>
                      </div>
                    </div>
                  )
                )}

                <div className="col-lg-6 px-4 pt-3">
                  <div className="row">
                    <label htmlFor="profile_pic" className="col-md-3 col-4 form-label" style={{ fontSize: "14px",lineHeight:'30px' }}>
                      Profile Image <span style={{color:'red'}}>*</span>
                    </label>
                    <div className="col-lg-9 col-md-8 col-8">
                      <input
                        id="profile_pic"
                        name="profile_pic"
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        className="form-control"
                        required
                        style={{ fontSize: "14px" }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 px-4 pt-3">
                  <div className="row">
                    <label htmlFor="about" className="col-md-3 col-4 form-label" style={{ fontSize: "14px",lineHeight:'30px' }}>
                      About <span style={{color:'red'}}>*</span>
                    </label>
                    <div className="col-lg-9 col-md-8 col-8">
                      <textarea
                        id="about"
                        name="about"
                        value={formData.about}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Tell us about yourself"
                        required
                        style={{ fontSize: "14px" }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 px-4 pt-3 d-flex justify-content-end">
                  <button
                    type="submit"
                    className="btn"
                    style={{
                      backgroundColor: "#00A192",
                      color: "white",
                      padding: "6px 8px",
                      fontSize: "14px",
                      borderRadius: "5px",
                      border: "none",
                      cursor: "pointer",
                      marginBottom: "10px",
                      transition: "background-color 0.3s",
                    }}
               
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>

            {feedbackMessage.message && (
              <div
                className={`alert alert-${
                  feedbackMessage.type === "success" ? "success" : "danger"
                } alert-dismissible fade show mt-3`}
                role="alert"
              >
                {feedbackMessage.message}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AddDoctor;