import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('token');
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && (result.error.status === 401 || result.error.status === 403)) {

    localStorage.removeItem('token');

  }

  return result;
};


export const concentrateApi = createApi({
  reducerPath: 'concentrateApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getConcentratelist: builder.query({
      query: () => ({
        url: `/user/concentrate-list/`,
        method:'GET',
      })
    }),
    postConcentrate: builder.mutation({
      query: (data) => ({
        url: `/user/concentrate-create/`,
        method:'POST',
        body:data
      })
    }),
    putConcentrate: builder.mutation({
      query: ({data,id}) => ({
        url: `/user/concentrate-update/${id}/`,
        method:'PUT',
        body:data
      })
    }),
  }),
})

export const { useGetConcentratelistQuery,usePostConcentrateMutation,usePutConcentrateMutation } = concentrateApi