import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('token');
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && (result.error.status === 401 || result.error.status === 403)) {

    localStorage.removeItem('token');

  }

  return result;
};


export const happyApi = createApi({
  reducerPath: 'happyApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getHappylist: builder.query({
      query: (params) => ({
        url: `/happy/list-admin/`,
        params: params,
      }),
    }),
  }),
})

export const { useGetHappylistQuery } = happyApi