import React, { useEffect, useRef, useState } from "react";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useLocation, useNavigate } from "react-router-dom";
import Loadercomp from "../../../components/Loadercomp";
import Breadcup from "../../../components/Breadcup";
import { useGetMeditationCategorylistQuery } from "../../../store/api/meditation-category-api";
import { usePostMeditationMutation } from "../../../store/api/meditationapi";

const Editmeditation = () => {
  const nvg = useNavigate();
  const location = useLocation();
  const [data, setdata] = useState({});
  const audioRef = useRef(null);
  const [loading, setloading] = useState(true);
  const [meditationresponse, setmeditationresponse] = useState("");
  const [meditationerror, setmeditationerror] = useState("");

  const {
    data: meditationcategorylist,
    isLoading,
    error,
  } = useGetMeditationCategorylistQuery();

  const [title, settitle] = useState("");
  const [category, setcategory] = useState("");
  const [categoryid, setcategoryid] = useState(0);
  const [audioFile, setaudioFile] = useState(null);
  const [audioFiledef, setaudioFiledef] = useState("");

  // Handle file change
  const handleFileChange = (e) => {
    setaudioFile(e.target.files[0]);
  };

  const [Createmeditation] = usePostMeditationMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("title", title);
    data.append("category", categoryid);
    data.append("audio", audioFile);
    const pro = Createmeditation(data);
    console.log("create api response", pro);
    settitle("");
    setcategoryid(0);

    setmeditationresponse("Uploading...");
    const response = await pro;
    if (response.data.id) {
      setmeditationresponse("Uploaded Successfully");
      setmeditationerror("");

      setTimeout(() => {
        setmeditationresponse("");
        nvg("/meditationlist");
      }, 3500);
    } else {
      setmeditationerror("Something Went Wrong Please Try Again");
      setmeditationresponse("");
    }
  };

  useEffect(() => {
    if (location.state.data) {
      setdata(location.state.data);
      settitle(location.state.data.title);
      setcategory(location.state.data.category_by_name);
      setcategoryid(location.state.data.category);
      setaudioFiledef(location.state.data.audio);
      setloading(false);
    }
  }, [location]);

  return (
    <div style={{ width: "100%", minHeight: "100vh" }}>
      <div className="dashboardcontent px-2">
        <Breadcup name={"Meditation"} secondname={"Edit Meditation"} />
        <div
          className="container-fuild px-2 mb-1"
          style={{ overflow: "hidden" }}
        >
          <div className="row bg-white ">
            <div
              className="col-lg-12 d-flex justify-content-between py-2"
              style={{ color: "#0C5398" }}
            >
              <p
                className="m-0 customfont teenheadertitle py-2"
                style={{ color: "#62153C" }}
              >
                Meditation
              </p>
              <div className="addnew d-block mb-2"></div>
            </div>
          </div>
        </div>

        {isLoading == true || loading == true ? (
          <div className="container-fuild bg-white">
            {" "}
            <div
              className="col-12 d-flex justify-content-center"
              style={{ gap: "4px", position: "absolute", width: "70%" }}
            >
              <div className="loading d-flex justify-content-center">
                <Loadercomp size={100} />
              </div>
            </div>
          </div>
        ) : (
          <div className="container-fuild pb-4 pt-3 px-2 bg-white">
            <form onSubmit={handleSubmit}>
              <div
                className="row bg-white round"
                style={{
                  border: "1px solid #E0E0E0",
                  margin: "10px 0px",
                  borderRadius: "3px",
                }}
              >
                <div className="col-lg-6 px-4 pt-3">
                  <div className="row">
                    <div className="col-md-3 col-4 d-flex align-items-center">
                      <label htmlFor="" className="form-label teenfontsize m-0">
                        Title<span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                      <input
                        name="title"
                        type="text"
                        value={title}
                        onChange={(e) => {
                          settitle(e.target.value);
                        }}
                        className="form-control teenfontsize2"
                        placeholder="Enter Title"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 px-4 pt-3">
                  <div className="row">
                    <div className="col-md-3 col-4 d-flex align-items-center">
                      <label
                        htmlFor=""
                        className="form-label teenfontsize customw m-0"
                      >
                        Category<span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9 col-md-8 col-8 d-flex justify-content-end">
                      <select
                        name="category"
                        value={categoryid}
                        onChange={(e) => {
                          setcategoryid(e.target.value);
                        }}
                        className="form-control teenfontsize2"
                        placeholder="Enter Category"
                        required
                      >
                        {meditationcategorylist.map((item) =>
                          categoryid == item.id ? (
                            <option value={item.id}>{item.name}</option>
                          ) : (
                            ""
                          )
                        )}
                        {meditationcategorylist.map((item) =>
                          categoryid != item.id ? (
                            <option value={item.id}>{item.name}</option>
                          ) : (
                            ""
                          )
                        )}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 px-4 pt-3">
                  <div className="row">
                    <div className="col-md-3 col-4 d-flex align-items-center">
                      <label htmlFor="" className="form-label teenfontsize m-0">
                        Upload Audio<span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9 col-md-8 col-8 d-flex">
                      <input
                        type="file"
                        name="audioFile"
                        accept="audio/*"
                        onChange={handleFileChange}
                        className="form-control teenfontsize2"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 px-4 pt-5">
                  <div className="row d-flex justify-content-end">
                    <div
                      className="px-2 d-flex justify-content-end pt-5 pb-2"
                      style={{ gap: "5px" }}
                    >
                      <button
                        type="button"
                        className="btn "
                        style={{
                          background: "#F2F2F2",
                          color: "#7C7878",
                          fontSize: "13px",
                          width: "fit-content",
                        }}
                        onClick={() => {
                          nvg("/meditationlist");
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn "
                        style={{
                          background: "#179F92",
                          color: "#fff",
                          fontSize: "13px",
                          width: "fit-content",
                        }}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>

                {meditationerror != "" ? (
                  <div className="col-lg-6 px-4 ">
                    <div className="row d-flex">
                      <div
                        className="px-2 d-flex pb-2 alert alert-danger"
                        role="alert"
                        style={{
                          gap: "5px",
                          position: "relative",
                          top: "-50px",
                        }}
                      >
                        {meditationerror}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {meditationresponse != "" ? (
                  <div className="col-lg-6 px-4 ">
                    <div className="row d-flex">
                      <div
                        className="px-2 d-flex  alert alert-success"
                        role="alert"
                        style={{
                          gap: "5px",
                          position: "relative",
                          top: "-50px",
                        }}
                      >
                        <span>{meditationresponse}</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default Editmeditation;
